import * as Sentry from '@sentry/nextjs';
import { AxiosError } from 'axios';

/**
 * # Sentry Event Tracking Guide
 *
 * | Feature        | Fingerprint                    | Tags                       | Extra                      |
 * |----------------|--------------------------------|----------------------------|----------------------------|
 * | **Purpose**    | Groups events                  | Adds metadata              | Adds debugging context     |
 * | **Effect**     | Controls error grouping        | Helps filter/search        | Provides detailed data     |
 * | **Searchable** | ❌ No                          | ✅ Yes                     | ❌ No                      |
 * | **Use Case**   | Custom grouping of errors      | Adding searchable metadata | Adding complex objects     |
 * | **Example**    | ["api-error", "POST-/login"]   | user_id: "123"             | payload: {complex: data}   |
 *
 * ## Fingerprints
 * Use for controlling how Sentry groups similar errors:
 * - Structure: ["category", "specific-error"] (e.g., ["api-error", "POST-/login"])
 * - Use when default stack trace grouping isn't sufficient
 * - Avoid over-grouping unrelated errors
 * [Learn more about fingerprints](https://docs.sentry.io/platform-redirect/?next=/data-management/event-grouping/fingerprinting/)
 *
 * ## Tags
 * Use for searchable metadata to help with:
 * - Filtering errors by user, feature, or type
 * - Analyzing error patterns
 * - Keep simple: string or number values only
 * [Learn more about tags](https://docs.sentry.io/product/sentry-basics/concepts/tags/)
 *
 * ## Extra Context
 * Use for additional debugging information:
 * - Complex objects (automatically serialized)
 * - Request/Response data
 * - Detailed state information
 * - Not searchable, but visible in event details
 * [Learn more about context](https://docs.sentry.io/platform-redirect/?next=/enriching-events/context/)
 */

export const useAlertSentry = () => {
  const alertSentry = ({
    error,
    errorMessage = '',
    fingerprints = [],
    customTags = {},
    extra = {},
  }: {
    error?: AxiosError | Error;
    errorMessage?: string;
    fingerprints?: string[];
    customTags?: Record<string, unknown>;
    extra?: Record<string, unknown>;
  }) => {
    Sentry.withScope((scope) => {
      if (fingerprints.length > 0) {
        scope.setFingerprint(fingerprints);
      }

      // Validate and sanitize custom tags
      const sanitizedTags = customTags
        ? Object.entries(customTags).reduce(
            (acc, [key, value]) => ({
              ...acc,
              [key.includes(':') ? key : `custom:${key}`]: value,
            }),
            {}
          )
        : {};

      scope.setTags({ ...sanitizedTags });

      if (Object.keys(extra).length > 0) {
        scope.setExtras({ ...extra });
      }

      if (errorMessage) {
        console.error(errorMessage);
        Sentry.captureMessage(errorMessage);
      }

      if (error) {
        Sentry.captureException(error);
      } else {
        const tagKeys =
          Object.keys(sanitizedTags).length > 0
            ? `[Tags: ${Object.keys(sanitizedTags).join(', ')}]`
            : '';
        const hasExtra =
          Object.keys(extra).length > 0 ? '[Has extra data]' : '';
        Sentry.captureException(
          new Error(
            `Manual Sentry capture ${tagKeys} ${hasExtra} at ${new Date().toISOString()}`
          )
        );
      }
    });
  };

  return {
    alertSentry,
  };
};
