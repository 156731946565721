import axios from 'axios';

import { signOut as nextSignOut, signIn } from 'next-auth/react';

import { utils as authUtils } from '@hl-portals/libs/auth';

import { LOGOUT_PATH } from '@hl-portals/constants';

import { config } from '@hl-portals/helpers';

interface SignOutProps {
  callbackUrl?: string;
  logoutParams?: {
    user: {
      impersonated: boolean;
    };
  };
}

export const signOut = async (options?: SignOutProps) => {
  try {
    // resolve callbackUrl
    const defaultCallbackUrl = authUtils.getSigninCallbackUrl(window?.location);

    const { callbackUrl = defaultCallbackUrl, logoutParams = {} } =
      options || {};

    // log out of hapi
    await axios({ url: LOGOUT_PATH, method: 'POST', data: logoutParams });

    // sign out of next auth without redirecting
    await nextSignOut({ redirect: false });

    localStorage.removeItem('hubspotVisitorToken');

    // start sign in process as defined by nextauth
    await signIn('homelight', {
      callbackUrl,
    });
  } catch (err) {
    console.error(err);
  }
};

export const signOutV2 = async (options?: SignOutProps) => {
  try {
    const {
      logoutParams = {},
      callbackUrl = `${window.location.protocol}//${window.location.host}/portal`,
    } = options || {};

    // log out of hapi
    await axios({ url: LOGOUT_PATH, method: 'POST', data: logoutParams });

    // sign out of next auth without redirecting
    await nextSignOut({ redirect: false });

    localStorage.removeItem('hubspotVisitorToken');

    window.location.href = `${config.homelightHost}/auth/logout?redirect_uri=${callbackUrl}`;
  } catch (err) {
    console.error(err);
  }
};
