import { useScreenSize } from '@hl-portals/hooks';

import { MobilePaymentDue } from '../Modals/MobilePaymentDue';
import { PaymentDueModal } from '../Modals/PaymentDue';
import { TeamMemberModal } from '../Modals/TeamMember';
import { ComplianceModalProps } from '../Modals/types';

const PaymentDueScreen = ({
  displayData,
  severity,
  type,
}: ComplianceModalProps) => {
  const { isDesktop } = useScreenSize(window.innerWidth);

  const isTeamMember = displayData.metadata?.contentType === 'team_member';

  let ComplianceToDisplay = TeamMemberModal;

  if (!isTeamMember) {
    ComplianceToDisplay = isDesktop ? PaymentDueModal : MobilePaymentDue;
  }

  return (
    <ComplianceToDisplay
      displayData={displayData}
      severity={severity}
      type={type}
    />
  );
};

export default PaymentDueScreen;
