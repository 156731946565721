import { IframeHTMLAttributes, useState } from 'react';
import styled from 'styled-components';

import { Box, BoxTypes } from '../Box';

interface VideoProps {
  videoSrc: string;
  placeholder?: string;
  containerProps?: BoxTypes;
  options?: IframeHTMLAttributes<any>;
}

export const Video = ({ videoSrc, placeholder, containerProps = {}, options = {} }: VideoProps) => {
  const [showVideo, setShowVideo] = useState(!placeholder);

  const renderPlaceholderImg = () => (
    <Placeholder
      src={placeholder}
      alt="Video placeholder"
      onClick={() => setShowVideo(true)}
    />
  );

  const renderVideo = () => (
    <VideoFrame
      src={videoSrc}
      title="Video"
      allowFullScreen
      {...options}
    />
  );

  return (
    <Box flex="1" {...containerProps}>
      { showVideo ? renderVideo() : renderPlaceholderImg() }
    </Box>
  );
};

const Placeholder = styled.img`
  width: 100%;
  cursor: pointer;
`;

const VideoFrame = styled.iframe`
  width: 100%;
  height: 50vw;
`;
