import { borderWidths, colors, radii } from '@homelight/particle-tokens';
import styled from 'styled-components';

import { Box } from '@hl-portals/ui';

import { media } from '@hl-portals/helpers';

export const Container = styled.div`
  margin-top: 4rem;
  padding: 0 1rem;
  display: flex;
  flex-direction: column;
  height: 100%;
`;

export const Footer = styled(Box)`
  justify-content: space-between;
  gap: 0.75rem;
  background: white;
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 2rem;

  ${media.untilTablet`
    bottom: 0;
    left: 0;
    right: 0;
    flex-direction: column;
    padding: 0 1.5rem 1.75rem;

    & > * {
      height: 3rem;
      line-height: 3rem;
    }
  `};
`;

const BaseContainer = styled.div`
  width: auto;
  max-width: 375px;
  height: auto;
  flex-grow: 1;
  padding: 1rem 1.5rem;
`;

export const PriceContainer = styled(BaseContainer)`
  gap: 0.5rem;
  border-radius: ${radii.borderRadius12}px;
  border: ${borderWidths.borderWidth1}px solid ${colors.cadmiumRed};
  margin-bottom: 0.75rem;
`;

export const CloseLeadsContainer = styled(BaseContainer)`
  gap: 0.25rem;
  border-radius: ${radii.borderRadius12}px;
  border: ${borderWidths.borderWidth1}px solid ${colors.coolGray4};
`;

export const NoticeContainer = styled(BaseContainer)`
  border-radius: ${radii.borderRadius12}px;
  border: ${borderWidths.borderWidth1}px solid ${colors.coolGray6};
  background: ${colors.coolGray6};
`;

export const ModalContent = styled.div`
  flex: 1;
  overflow-y: auto;
  padding-bottom: 6rem; /* Ensure space for fixed footer */
`;
