export default () => (
  <svg
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M40 20C40 31.0457 31.0457 40 20 40C8.95429 40 0 31.0457 0 20C0 8.95429 8.95429 0 20 0C31.0457 0 40 8.95429 40 20Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.72852 28.1319C4.84509 35.1236 11.8511 40.0002 19.9999 40.0002C28.1454 40.0002 35.1485 35.1279 38.2674 28.1414L20.0114 14.8511L1.72852 28.1319ZM20.8634 28.1451H24.7251V23.7031H20.8634V28.1451ZM20.8634 34.3037H24.7251V29.8617H20.8634V34.3037ZM15.2854 34.3037H19.1474V29.8617H15.2854V34.3037ZM15.2854 28.1451H19.1474V23.7031H15.2854V28.1451Z"
      fill="#273653"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M39.5529 24.208C39.8437 22.8509 40 21.444 40 20C40 8.95429 31.046 0 20 0C8.95429 0 0 8.95429 0 20C0 21.4403 0.155714 22.844 0.445143 24.198L20.0126 9.98171L39.5529 24.208Z"
      fill="#46B6FF"
    />
  </svg>
);
