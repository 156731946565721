import {
  ChangeEvent,
  Dispatch,
  Fragment,
  SetStateAction,
  useEffect,
  useRef,
  useState,
} from 'react';

import {
  Box,
  Icon,
  Input,
  InputProps,
  Paragraph,
  Spinner,
} from '@hl-portals/ui';

import { theme } from '@hl-portals/constants';

import { useHandleClickOutside } from '@hl-portals/hooks';

import { HoverableBox, ResultItem, ResultWrapper } from './styles';

export type AutocompleteProps<T> = {
  onChange: (params: {
    term: string;
    selectedIndex: number;
    setTerm: Dispatch<SetStateAction<string>>;
  }) => void;
  onEnter: (params: {
    option: T;
    setTerm: Dispatch<SetStateAction<string>>;
  }) => void;
  options: Array<T & { disabled?: boolean }>;
  defaultOptions?: Array<T & { disabled?: boolean }>;
  autofocus?: boolean;
  placeholder?: string;
  separator?: () => React.ReactElement;
  children?: (params: { option: T; index: number }) => React.ReactElement;
  value: string;
  isError?: boolean;
  name?: string;
  fallback?: (params: {
    term: string;
    setTerm: Dispatch<SetStateAction<string>>;
    setIsResultOpen: Dispatch<SetStateAction<boolean>>;
  }) => React.ReactElement;
  onFallback?: (params: {
    term: string;
    setTerm: Dispatch<SetStateAction<string>>;
  }) => void;
  tabProtection?: boolean;
  variant?: 'top' | 'bottom';
  disabled?: boolean;
  optionKey?: string;
  dropdownPlaceholder?: string;
  emptyResultsText?: string;
  isLoading?: boolean;
  loadingText?: string;
  hideSearchIcon?: boolean;
  sync?: boolean;
  toggleManualAddress?: () => void;
  customInputLabel?: string;
};

type InputPropExtras<T> = Omit<InputProps, keyof AutocompleteProps<T>>;

function Autocomplete<T>({
  autofocus,
  placeholder,
  onChange: onChangeProp,
  onEnter,
  children,
  options,
  defaultOptions,
  separator: CustomDivider,
  value = '',
  name,
  isError,
  onFocus,
  fallback,
  onFallback,
  tabProtection = false,
  variant = 'bottom',
  optionKey,
  dropdownPlaceholder,
  emptyResultsText,
  isLoading,
  loadingText = 'Loading...',
  toggleManualAddress,
  customInputLabel = 'Enter address manually',
  hideSearchIcon,
  sync,
  ...extraProps
}: AutocompleteProps<T> & InputPropExtras<T>): React.ReactElement {
  const inputRef = useRef<HTMLInputElement>();
  const isTypingRef = useRef<boolean>(false);
  const timeoutRef = useRef<ReturnType<typeof setTimeout>>();
  const [term, setTerm] = useState(value);
  const [selectedIndex, setSelectedIndex] = useState(-1);
  const [isResultOpen, setIsResultOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState(false);

  const handleFocus = (e: React.FocusEvent<HTMLInputElement>) => {
    onFocus && onFocus(e);

    setIsResultOpen(!!dropdownPlaceholder || !!emptyResultsText);
  };

  useEffect(() => {
    if (inputRef.current && autofocus) {
      inputRef.current.focus();
    }
  }, [autofocus]);

  useEffect(() => {
    setTerm(value);
  }, [value]);

  const ref = useHandleClickOutside<HTMLInputElement>({
    onClickOutside: () => {
      if (isResultOpen && tabProtection) setTerm('');
      setIsResultOpen(false);
    },
  });

  const onChange = (event: ChangeEvent<HTMLInputElement>) => {
    isTypingRef.current = true;
    if (timeoutRef.current) clearTimeout(timeoutRef.current);
    onChangeProp({ term: event.target.value, selectedIndex, setTerm });

    setIsResultOpen(true);
    setSelectedIndex(-1);
    setSelectedOption(false);

    timeoutRef.current = setTimeout(() => {
      isTypingRef.current = false;
    }, 0);
  };

  // @ts-ignore
  const onClick = ({ option }) => {
    if (option.disabled || isLoading) return;

    onEnter({ option, setTerm });
    setIsResultOpen(false);
  };

  // @ts-ignore
  const onKeyDown = (event) => {
    switch (event.key) {
      case 'Tab':
        if (!selectedOption && tabProtection) setTerm('');

        setIsResultOpen(false);
        break;
      case 'ArrowDown':
        event.preventDefault();
        setSelectedIndex(
          selectedIndex === options.length - 1
            ? options.length - 1
            : selectedIndex + 1
        );
        break;
      case 'ArrowUp':
        event.preventDefault();
        setSelectedIndex(selectedIndex === 0 ? 0 : selectedIndex - 1);
        break;
      case 'Enter':
        event.preventDefault();
        if (options.length === 1) {
          onEnter({ option: options[0], setTerm });
          setIsResultOpen(false);
        } else if (selectedIndex > -1) {
          onEnter({ option: options[selectedIndex], setTerm });
        } else if (fallback && onFallback && options.length === 0) {
          onFallback({ term, setTerm });
        }
        setSelectedOption(true);
        setIsResultOpen(false);
        break;
      case 'Escape':
        if (!isResultOpen) break;
        event.preventDefault();
        setTerm('');
        setIsResultOpen(false);
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    if (defaultOptions) {
      setIsResultOpen(true);
    }
  }, [defaultOptions]);

  const loading = !sync && (isTypingRef.current || isLoading);

  return (
    <Box position="relative" ref={ref}>
      <Box position="relative" zIndex={isResultOpen && 100} width={1}>
        <Input
          variant={hideSearchIcon ? 'normal' : 'search'}
          autoComplete="off"
          placeholder={placeholder}
          value={term}
          onChange={onChange}
          onKeyDown={onKeyDown}
          isError={isError}
          onFocus={handleFocus}
          name={name}
          // @ts-ignore
          ref={inputRef}
          {...extraProps}
        />
      </Box>

      <ResultWrapper
        isOpen={
          (term?.length > 0 || !!emptyResultsText || !!dropdownPlaceholder) &&
          isResultOpen
        }
        variant={variant}
      >
        <div>
          {loading && (
            <ResultItem variant={variant} selected p="12px" disabled>
              <Box flexDirection="row" width={1} alignItems="center" gap="12px">
                <Box alignItems="center" justifyContent="center">
                  <Spinner md />
                </Box>
                <Paragraph
                  variant="text-small"
                  mr="12px"
                  data-test={`address-input-result-loading`}
                  color={theme.colors.gray600}
                >
                  {loadingText}
                </Paragraph>
              </Box>
            </ResultItem>
          )}
          {!loading &&
            options.map((option, index) => {
              const key = `autocomplete-result-${index}`;

              if (!children) {
                return (
                  <ResultItem
                    key={key}
                    selected={selectedIndex === index}
                    p="12px"
                    onMouseOver={() => setSelectedIndex(index)}
                    onClick={() => onClick({ option })}
                    variant={variant}
                  >
                    {term}
                  </ResultItem>
                );
              }

              return (
                <Fragment key={key}>
                  <ResultItem
                    disabled={loading}
                    selected={selectedIndex === index}
                    onMouseOver={() => setSelectedIndex(index)}
                    onClick={() => onClick({ option })}
                    variant={variant}
                  >
                    {children({ option, index })}
                  </ResultItem>
                  {/* @ts-ignore */}
                  {index !== options.length - 1 && CustomDivider && (
                    <CustomDivider />
                  )}
                </Fragment>
              );
            })}
          {options.length === 0 && fallback && (
            <ResultItem variant={variant} selected p="12px">
              {fallback({ term, setTerm, setIsResultOpen })}
            </ResultItem>
          )}
          {options.length === 0 &&
            !fallback &&
            !!term.length &&
            !loading &&
            emptyResultsText && (
              <ResultItem variant={variant} selected p="12px" disabled>
                {emptyResultsText}
              </ResultItem>
            )}
          {options.length === 0 &&
            !fallback &&
            !term.length &&
            !loading &&
            dropdownPlaceholder && (
              <ResultItem variant={variant} selected p="12px" disabled>
                {dropdownPlaceholder}
              </ResultItem>
            )}
          {!loading && toggleManualAddress && (
            <HoverableBox
              style={{
                padding: '0.5rem',
              }}
              onClick={() => {
                toggleManualAddress();
                setIsResultOpen(false);
              }}
            >
              <Icon
                size={12}
                type="plus"
                fill="#46B6FF"
                pt={options.length > 0 ? '0' : '0.5rem'}
              />
              <Paragraph
                variant="text-small"
                color="#46B6FF"
                pt={options.length > 0 ? '0' : '0.5rem'}
              >
                {customInputLabel}
              </Paragraph>
            </HoverableBox>
          )}
        </div>
      </ResultWrapper>
    </Box>
  );
}

export default Autocomplete;
