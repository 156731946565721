import { useScreenSize } from '@hl-portals/hooks';

import { MobileStripeCheckoutModal } from '../Modals/MobileStripeCheckout';
import { StripeCheckoutModal } from '../Modals/StripeCheckout';
import { StripeCheckoutProps } from '../Modals/types';

const StripeCheckoutScreen = ({
  displayData,
  paymentMethod,
  severity,
}: StripeCheckoutProps) => {
  const { isDesktop } = useScreenSize(window.innerWidth);

  const ComplianceToDisplay = isDesktop
    ? StripeCheckoutModal
    : MobileStripeCheckoutModal;

  return (
    <ComplianceToDisplay
      severity={severity}
      displayData={displayData}
      paymentMethod={paymentMethod}
    />
  );
};

export default StripeCheckoutScreen;
