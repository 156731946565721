import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
} from 'react';

import { useStack } from '@hl-portals/hooks';

export type ModalStatus =
  | 'CLOSED'
  | 'CLOSING'
  | 'OPENING'
  | 'OPEN'
  | 'OPENING_DRAWER'
  | 'CLOSING_DRAWER';

type ModalState = {
  currentStatus: ModalStatus;
  options: ModalOptions;
  component?: React.ReactElement;
};

type TModalContext = {
  stack: Array<ModalState>;
  state: ModalState;
  closeModal: (params?: { force?: boolean; clearAll?: boolean }) => void;
  openModal: (component: React.ReactElement, options?: ModalOptions) => void;
  onAnimationEnd?: () => void;
  updateModalOptions?: (options: ModalOptions) => void;
};

export type ModalOptions = {
  hideCloseIcon?: boolean;
  modalDataTest?: string;
  enableBackDropClick?: boolean;
  enableCloseOnEsc?: boolean;
  wrapperClassName?: string;
  closeButtonClassName?: string;
  onModalClose?: () => void;
  onModalClosing?: () => void;
  enableClose?: boolean;
  ignoreStack?: boolean;
  asDrawer?: boolean;
};

type ModalProps = {
  children: React.ReactElement;
};

const DEFAULT_OPTIONS: ModalOptions = {
  enableBackDropClick: false,
  enableCloseOnEsc: false,
  wrapperClassName: '',
  closeButtonClassName: '',
  onModalClose: () => {},
  onModalClosing: () => {},
  enableClose: true,
  hideCloseIcon: false,
  ignoreStack: false,
  asDrawer: false,
};

const INITIAL_STATE: ModalState = {
  currentStatus: 'CLOSED',
  options: DEFAULT_OPTIONS,
};

export const ModalContext = createContext<TModalContext>({
  stack: [],
  state: INITIAL_STATE,
  openModal: () => {},
  closeModal: () => {},
});
ModalContext.displayName = 'ModalContext';

export const ModalProvider: React.FC<ModalProps> = ({ children }) => {
  const modalStack = useStack<ModalState>();

  const openedModal = modalStack.top ?? INITIAL_STATE;

  const openModal = useCallback(
    (component: React.ReactElement, options?: ModalOptions) => {
      modalStack.add({
        currentStatus: options?.asDrawer ? 'OPENING_DRAWER' : 'OPENING',
        component,
        options: { ...DEFAULT_OPTIONS, ...options },
      });
    },
    [modalStack]
  );

  const updateModalOptions = (options: ModalOptions) => {
    modalStack.updateTop({
      ...openedModal,
      options: { ...openedModal.options, ...options },
    });
  };

  const onOpenedHandler = () => {
    modalStack.updateTop({ ...openedModal, currentStatus: 'OPEN' });
  };

  const closeModal = (params?: { force?: boolean; clearAll?: boolean }) => {
    if (openedModal.options.onModalClose) openedModal.options.onModalClose();
    if (params?.clearAll) {
      modalStack.clear();
    } else if (params?.force) {
      modalStack.remove();
    } else if (openedModal.options.enableClose) {
      const { asDrawer } = openedModal.options;
      modalStack.updateTop({
        ...openedModal,
        currentStatus: asDrawer ? 'CLOSING_DRAWER' : 'CLOSING',
      });
    }
  };

  const onClosedHandler = () => {
    if (openedModal.options.ignoreStack) {
      modalStack.clear();
    }
    if (openedModal.options.enableClose) {
      modalStack.remove();
    }
    if (openedModal.options.onModalClosing)
      openedModal.options.onModalClosing();
  };

  const onAnimationEnd = () => {
    switch (openedModal.currentStatus) {
      case 'OPENING':
        onOpenedHandler();
        break;
      case 'CLOSING':
        onClosedHandler();
        break;
      case 'OPENING_DRAWER':
        onOpenedHandler();
        break;
      case 'CLOSING_DRAWER':
        onClosedHandler();
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    const $body = document.querySelector('body');

    if ($body) {
      if (openedModal.currentStatus === 'OPEN') {
        $body.style.overflow = 'hidden';
      } else {
        $body.style.overflow = 'auto';
      }
    }
  }, [openedModal.currentStatus]);

  return (
    <ModalContext.Provider
      value={{
        stack: modalStack.stack,
        state: openedModal,
        closeModal,
        openModal,
        onAnimationEnd,
        updateModalOptions,
      }}
    >
      {children}
    </ModalContext.Provider>
  );
};

export const useModal = () => {
  const { closeModal, openModal, updateModalOptions, state } =
    useContext(ModalContext);
  return {
    closeModal,
    openModal,
    updateModalOptions,
    isModalOpened: state.currentStatus.includes('OPEN'),
  };
};
