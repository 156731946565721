import { useState } from 'react';
import { toast } from 'react-toastify';

import { usePayment } from '../../hooks/usePayment';

export const usePaymentDueActions = (agentId?: string) => {
  const [isLoading, setIsLoading] = useState(false);
  const { createSupportTask } = usePayment(agentId);

  const onSupportTaskClick = async () => {
    if (isLoading) return;
    setIsLoading(true);

    try {
      await createSupportTask();
      toast(
        'One of our Closing Coordinators will call you shortly to discuss this invoice.',
        { type: 'success' }
      );
    } catch (error) {
      toast('There was an error. Please try again or contact support.', {
        type: 'error',
      });
    } finally {
      setIsLoading(false);
    }
  };

  return {
    isLoading,
    onSupportTaskClick,
  };
};
