import {
  display,
  flexbox,
  palette,
  sizing,
  spacing,
  typography,
} from '@mui/system';
import styled, { css } from 'styled-components';

import { Paragraphs } from '@hl-portals/constants';

import { paragraphHelper } from '@hl-portals/helpers';

import { BoxTypes } from '../Box';

const StyledLink = styled.a<SimpleLinkProps>`
  ${({ theme, underlined, font }) => css`
    ${underlined &&
    css`
      text-decoration: underline;
    `};
    ${paragraphHelper({ type: font ?? 'text-small', theme })};
  `}
  ${display};
  ${sizing};
  ${flexbox};
  ${spacing};
  ${palette};
  ${typography};

  &:hover {
    cursor: pointer;
    text-decoration: underline;
  }
`;

type SimpleLinkProps = {
  href?: string;
  target?: string;
  rel?: string;
  onClick?: (event: React.MouseEvent<HTMLElement>) => void;
  font?: Paragraphs;
  underlined?: boolean;
  'data-test'?: string;
  children: React.ReactNode;
} & BoxTypes;

const SimpleLink = ({
  href,
  target,
  rel,
  onClick,
  font,
  underlined = false,
  children,
  'data-test': dataTest,
  ...otherProps
}: SimpleLinkProps): React.ReactElement => (
  <StyledLink
    href={href}
    target={target}
    rel={rel}
    onClick={onClick}
    font={font}
    underlined={underlined}
    data-test={dataTest}
    {...otherProps}
  >
    {children}
  </StyledLink>
);

export default SimpleLink;
