import { borderWidths, colors, radii } from '@homelight/particle-tokens';
import styled from 'styled-components';

import { Box } from '@hl-portals/ui';

import { media } from '@hl-portals/helpers';

export const Container = styled(Box)`
  width: 100%;
  height: auto;
  max-width: 540px;
  flex-direction: column;
  padding: 1.25rem 2rem 2.5rem;

  border: ${borderWidths.borderWidth1}px solid ${colors.coolGray5};
  border-radius: ${radii.borderRadius12}px;
`;

export const RestrictedImageWrapper = styled.div`
  text-align: center;
  margin-bottom: 1.25rem;
  padding-top: 2rem;
  img {
    height: 7.5rem;
  }

  ${media.shortPhone`
    padding-top: 0;
    
    img {
      height: 6rem;
    }
  `}
`;
