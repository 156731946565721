import { useEffect, useState } from 'react';
import * as Sentry from '@sentry/nextjs';
import styled from 'styled-components';
import { toast } from 'react-toastify';

import { PPL_ACCEPT_AGREEMENT } from '@hl-portals/constants';
import { useUserEvents } from '@hl-portals/hooks';
import { BREAKPOINT_VALUES, config, enhancedAxios, media } from '@hl-portals/helpers';
import { Box, Button, Checkbox, Paragraph } from '@hl-portals/ui';
import {
  TCPA_ADDENDUM_CHANGE_SUMMARY,
  TCPA_ADDENDUM_TERMS,
  TCPA_GRANDFATHERED_SUMMARY,
  TCPA_GRANDFATHERED_TERMS,
} from '../../../constants/agreement';
import { useUser } from '../../../contexts/Auth';


const AgreementAddendumModal = ({ onDismiss, agentAgreementChanges, ...props }) => {
  const [viewedAt, setViewedAt] = useState(null);
  const [agreementAccepted, setAgreementAccepted] = useState(false);
  const [termsCompletelyScrolled, setTermsCompletelyScrolled] = useState(false);
  const { recordUserEvent } = useUserEvents();
  const { user, featureFlags } = useUser();

  let addendumSummary = TCPA_ADDENDUM_CHANGE_SUMMARY;
  let addendumTerms = TCPA_ADDENDUM_TERMS;
  if (agentAgreementChanges.content_type === 'grandfathered') {
    addendumSummary = TCPA_GRANDFATHERED_SUMMARY;
    addendumTerms = TCPA_GRANDFATHERED_TERMS;
  }

  const userName = `${user.first_name} ${user.last_name}`;
  const isDesktop = window.innerWidth > BREAKPOINT_VALUES.md;
  const isSpecialTinyScroll = window.innerWidth <= BREAKPOINT_VALUES.sm && window.innerHeight <= 700;
  const currentTermsId = user.agent.ppl_provider_agreements?.length ?
    user.agent.ppl_provider_agreements[0].agreement_id :
    null;

  // @ts-ignore
  const startHotjarEvent = typeof hj === 'function' ? hj : ()=>{};

  useEffect(() => {
    if (config.environmentName === 'production') {
      startHotjarEvent('event', `tcpa_addendum`);
    }
  }, [startHotjarEvent]);

  const trackEvent = (interaction = '') => {
    recordUserEvent({
      source: 'agent_dashboard',
      event_action: 'terms_update_modal',
      includeSource: false,
      result: {
        interaction: interaction,
        platform: isDesktop ? 'Desktop' : 'Mobile Web',
        agreement_owner_user_id: user.id.toString(),
        agent_id: user.agent.id,
        agreement_id: currentTermsId,
        content_type: agentAgreementChanges.content_type,
        agreement_type: agentAgreementChanges.agreement_type,
      },
    });
  }

  useEffect(() => {
    setViewedAt(new Date().toISOString());
    trackEvent('View');
  }, []);

  const successToast = () => {
    toast.success(
    'You’ve agreed to the Referral Agreement updates and a copy will be accessible in your settings.', {
      toastId: 'addendum-accept-success',
      position: isDesktop ? 'bottom-center' : 'top-center',
      style: { top: 15 },
    });
  }

  const onAgreeSubmit = async () => {
    if (!agreementAccepted) {
      return;
    }

    const signedAt = new Date().toISOString();

    await enhancedAxios({
      method: 'POST',
      url: PPL_ACCEPT_AGREEMENT(),
      data: {
        agreement_type: 'addendum',
        viewed_at: viewedAt,
        signed_at: signedAt,
      },
    }).then((res) => {
      trackEvent('Submit');
      successToast();
      props.onAfterSubmit();
    }).catch((err) => {
      Sentry.captureException(err);
      const responseStatus = err.response?.status;
      if (responseStatus === 403 && featureFlags.is_coordinator) {
        toast.error('User with coordinator role may not accept');
      } else {
        toast.error('Sorry, something went wrong');
      }
    });
  }

  const checkScrollProgress = (container) => {
    if (!termsCompletelyScrolled) {
      const { scrollHeight, scrollTop, clientHeight } = container;
      const scrollProgress = Math.abs(scrollHeight - clientHeight - scrollTop);
      const bottomBufferPixels = 24;
      if (scrollProgress < bottomBufferPixels) {
        setTermsCompletelyScrolled(true);
      }
    }
  }

  const onTermsScroll = (event) => {
    if (isSpecialTinyScroll || !event?.target) { return; }

    checkScrollProgress(event.target);
  }

  const onContentScroll = (event) => {
    if (!isSpecialTinyScroll || !event?.target) { return; }

    checkScrollProgress(event.target);
  }

  const onCheckboxChange = () => {
    if (agreementAccepted) {
      setAgreementAccepted(false);
      return;
    }

    if (termsCompletelyScrolled) {
      setAgreementAccepted(true);
    } else {
      toast.info(
      'Scroll through updated terms and select the checkbox before agreeing.', {
        toastId: 'scroll-check',
        position: isDesktop ? 'bottom-center' : 'top-center',
        style: { top: 15 },
      });
    }
  }

  return (
    <>
      <AddendumContent onScroll={onContentScroll} className="visible-scrollbar-hack">
        <AddendumOverview>
          <NoticeTitle className="top-section">
            <ImageWrapper className="modal-image">
              <img src="/illustrations/agreement.png" />
            </ImageWrapper>
            <Paragraph variant="heading-4" className="modal-title">
              Our Referral Agreement terms have changed
            </Paragraph>
          </NoticeTitle>
          <AddendumSummaryPoints className="changes-summary">
            <WhatsChanging>
              What's changing?
            </WhatsChanging>
            <SummaryList>
              {addendumSummary.map((item, idx) => (
                <li key={`tcpa-summary-${idx}`}>{item}</li>
              ))}
            </SummaryList>
          </AddendumSummaryPoints>
        </AddendumOverview>

        <SectionBreak />

        <AddendumBody>
          <PleaseReview variant="heading-5">
            To continue receiving referrals, please review and agree to the new terms below.
          </PleaseReview>
          <AddendumLegalText onScroll={onTermsScroll} className="full-addendum-terms visible-scrollbar-hack">
            <ol>
              {addendumTerms.map((item, idx) => (
                <li key={`tcpa-term-${idx}`}>{item}</li>
              ))}
            </ol>
          </AddendumLegalText>
        </AddendumBody>
      </AddendumContent>

      <CheckToAgree>
        <Checkbox
          checked={agreementAccepted}
          onChange={onCheckboxChange}
        />
        <Paragraph onClick={onCheckboxChange} variant="text-small" color="darkBlue">
          {`By checking this box I, ${userName}, agree to the terms above.`}
        </Paragraph>
      </CheckToAgree>

      <AgreeButton
        size="large"
        mt="32px"
        width="100%"
        disabled={!agreementAccepted}
        onClick={onAgreeSubmit}
      >
        Agree to terms
      </AgreeButton>
    </>
  );
};

// styles //

const AddendumContent = styled(Box)`
  max-height: 85%;
  flex-direction: column;
  -webkit-text-size-adjust: none;
  -webkit-font-smoothing: antialiased;
  -moz-font-smoothing: antialiased;
  gap: 1rem;

  ${media.desktop`
    max-height: 400px;
    flex-direction: row;
    gap: 1.5rem;
  `}

  @media (max-width: 390px) {
    .modal-title {
      font-size: 1.125rem;
    }

    .changes-summary {
      font-size: 0.75rem;
    }

    .full-addendum-terms {
      font-size: 0.75rem;
    }
  }

  ${media.shortPhone`
    max-height: 75%;
    height: 80%;
    overflow: scroll;

    .top-section {
      margin-bottom: 0;
    }

    .modal-image, .changes-summary {
      display: none;
    }
  `}
`;

const AddendumOverview = styled.div`
  ${media.desktop`
    flex: 1;
    margin-bottom: 1.5rem;
  `}
`;

const AddendumBody = styled.div`
  min-height: 0;
  display: flex;
  flex-direction: column;
  margin-bottom: 1.5rem;

  ${media.desktop`
    flex: 1;
    padding-top: 0.75rem;
  `}

  ${media.shortPhone`
    min-height: auto;
  `}

  ${Paragraph} {
    line-height: 150%;
  }
`;

const PleaseReview = styled(Paragraph)`
  font-size: 0.875rem;
  ${media.desktop`
    font-size: 1rem;
  `}
`;

const ImageWrapper = styled.div`
  img {
    width: 5rem;
    height: 5rem;
  }
`;

const NoticeTitle = styled(Box)`
  gap: 1rem;
  margin-bottom: 1.25rem;

  ${Paragraph} {
    display: flex;
    align-items: center;
    line-height: 150%;
  }

  ${media.desktop`
    margin-bottom: 1rem;
  `}
`;

const AddendumSummaryPoints = styled.div`
  ${({ theme }) => `
    padding: 1rem;
    background-color: ${theme.colors.mistBlue};
    color: ${theme.colors.blue700};
    border-radius: 6px;

  `}
  ${Paragraph} {
    color: inherit;
    line-height: 150%;
  }
`;

const WhatsChanging = styled.h6`
  ${({ theme }) => `
    font-weight: 600;
    color: ${theme.colors.blue700};
    margin-bottom: 0.375rem;
  `}
`;

const SummaryList = styled.ul`
  ${({ theme }) => `
    color: ${theme.colors.blue700};
  `}

  li {
    display: list-item;
    list-style: disc;
    margin-left: 1.25rem;
    color: inherit;
    line-height: 150%;
  }
`;

const SectionBreak = styled.div`
  ${({ theme }) => `
    background-color: ${theme.colors.coolGray4};
    flex: 0 0 1px;
    align-self: stretch;
  `}
`;

const AddendumLegalText = styled(Box)`
  ${({ theme }) => `
    flex-direction: column;
    overflow: scroll;
    margin-top: 1rem;
    padding: 0.625rem 0.375rem;
    background-color: ${theme.colors.gray50};
    color: ${theme.colors.gray700};
    border-radius: 6px;
  `}

  li {
    display: list-item;
    list-style: auto;
    margin-left: 1.5rem;
    color: inherit;
    line-height: 150%;

    &:not(:last-of-type) {
      margin-bottom: 0.75rem;
    }
  }

  ul {
    margin-top: 0.75rem;

    & > li {
      list-style: disc;
    }
  }

  strong {
    font-weight: 700;
  }
`;

const CheckToAgree = styled(Box)`
  justify-content: flex-start;

  label {
    cursor: pointer;
  }

  ${media.shortPhone`
    padding-top: 1rem;
  `}

  ${Paragraph} {
    padding-left: 0.625rem;
    line-height: 150%;
    cursor: pointer;
    user-select: none;
  }
`;

const AgreeButton = styled(Button)`
  margin-top: 1rem;

  ${media.desktop`
    margin-top: 1.5rem;
  `}
`;

export default AgreementAddendumModal;
