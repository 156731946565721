import {
  ComplianceDisplayDataResponse,
  ContentItem,
  ContentMap,
  Potential,
} from './types';

export class ComplianceDisplayData {
  banners!: ContentMap;

  modals!: ContentMap;

  metadata?: ContentMap;

  constructor(complianceDisplayDataAttributes: ComplianceDisplayData) {
    Object.assign(this, complianceDisplayDataAttributes);
  }

  static create(
    complianceDisplayDataResponse: Potential<ComplianceDisplayDataResponse>
  ): Potential<ComplianceDisplayData> {
    if (!complianceDisplayDataResponse) {
      return;
    }

    const formattedData = this.formatData(complianceDisplayDataResponse);
    return new ComplianceDisplayData({
      banners: formattedData.banners,
      modals: formattedData.modals,
      metadata: formattedData.metadata,
    });
  }

  private static formatData(data: ComplianceDisplayData): ContentMap {
    const formatted: ContentMap = {};

    Object.entries(data).forEach(([category, items]) => {
      formatted[category] = {};

      // Metadata
      if (category === 'metadata') {
        Object.entries(items).map(([key, item]) => {
          const formattedId = this.toCamelCase(key);
          formatted[category][formattedId] = item;
        });
      } else {
        (items as ContentItem[]).forEach((item) => {
          const formattedId = this.toCamelCase(item.id);
          formatted[category][formattedId] = this.formatItem(item);
        });
      }
    });

    return formatted;
  }

  private static formatItem(item: ContentItem): ContentMap {
    const formatted: ContentMap = {};

    // Handle content
    if (Array.isArray(item.content)) {
      item.content.forEach((content) => {
        const id = this.toCamelCase(content.id);
        formatted[id] = Array.isArray(content.content)
          ? this.formatItem(content)
          : content.content;
      });
    } else {
      formatted.content = item.content;
    }

    // Handle actions
    if (Array.isArray(item.actions)) {
      item.actions.forEach((action) => {
        const id = this.toCamelCase(action.id);
        formatted[id] = action;
      });
    }

    // Handle resources
    if (Array.isArray(item.resources)) {
      item.resources.forEach((resource) => {
        const id = this.toCamelCase(resource.id);
        formatted[id] = resource;
      });
    }

    return formatted;
  }

  private static toCamelCase(str: string): string {
    return str.replace(/([-_][a-z])/g, (group: string) =>
      group.toUpperCase().replace(/[-_]/, '')
    );
  }
}
