import styled, { createGlobalStyle } from 'styled-components';

import { useRouter } from 'next/router';

import { enhancedAxios, media } from '@hl-portals/helpers';
import { useModal, useUserEvents } from '@hl-portals/hooks';
import { Box, Button, Paragraph } from '@hl-portals/ui';

import { OrangeButton } from '../Referrals/styles/button';
import { BENJI_ZENDESK_ARTICLE } from '../../../constants/links';
import { ACCEPT_ADDENDUM_URL_PARAM } from '../../../constants/agreement';
import { useEffect } from 'react';
import { AGENT_REFERRAL_AGREEMENT_ESIGN } from '@hl-portals/constants';
import { useUser } from 'apps/agent-dashboard/contexts/Auth';

const BlockAlertWrapper = styled(Box)`
  ${media.untilDesktop`
    padding: 0 1rem;
  `}
`;

const RestrictedImageWrapper = styled.div`
  text-align: center;
  margin-bottom: 1.25rem;
  padding-top: 2rem;
  img {
    height: 7.5rem;
  }

  ${media.shortPhone`
    padding-top: 0;
    
    img {
      height: 6rem;
    }
  `}
`;

const ClaimRestrictionWrapper = createGlobalStyle`
  .claim-restricted-modal {
    max-width: 540px;

    ${media.untilDesktop`
      ${RestrictedImageWrapper} {
        padding-top: 4rem;
      }
    `}
  }
`;

const ReferralsBlockedAlert = ({ onDismiss, agentAgreementChanges, ...props }) => {
  const router = useRouter();
  const { closeModal } = useModal();
  const isAddendum = agentAgreementChanges?.agreement_type === 'addendum';
  const isTeamMember = agentAgreementChanges?.content_type === 'team_member';
  const title = agentAgreementChanges?.modal_copy_title || 'You are no longer able to claim new HomeLight referrals';
  const body = agentAgreementChanges?.modal_copy_body
    || 'Review and sign our new pricing terms and start connecting with new referrals today.';

  let onNavAction = () => router.push('/settings/referral-agreement');
  let buttonText = 'Sign referral agreement';
  let StyledActionButton = Button;
  if (isAddendum) {
    // @ts-ignore
    onNavAction = () => location.href = `/dashboard?${ACCEPT_ADDENDUM_URL_PARAM}`;
    buttonText = 'Review updated terms';
    StyledActionButton = OrangeButton;
  }

  if (isTeamMember) {
    onNavAction = () => router.push('/dashboard');
    buttonText = 'Go to dashboard';
    StyledActionButton = Button;
  }

  const { recordUserEvent } = useUserEvents();
  const { user } = useUser();

  useEffect(() => {
    const trackLoad = async () => {
      const agreement = await enhancedAxios({
        url: AGENT_REFERRAL_AGREEMENT_ESIGN,
        translateJsona: false
      });
      const agreementVersion = agreement.agreement_id;

      recordUserEvent({
        source: 'agent_dashboard',
        event_action: 'agreement_restriction_view',
        includeSource: false,
        result: {
          platform: 'web',
          source: 'dashboard',
          treatment: 'modal',
          version_required: agreementVersion,
          agent_id: user.agent.id.toString(),
          agreement_id: user?.agent?.ppl_provider_agreements?.[0]?.agreement_id || agreement.agreement_id,
          agreement_type: agentAgreementChanges.agreement_type,
        },
      });
    }

    trackLoad();
  }, [])

  return (
    <BlockAlertWrapper {...props} flexDirection="column">
      <ClaimRestrictionWrapper />
      <RestrictedImageWrapper>
        <img src='/illustrations/restricted.png' />
      </RestrictedImageWrapper>

      <Paragraph variant='heading-4' textAlign={{xs: 'center'}} data-test='claim-restricted-title'>
        {title}
      </Paragraph>

      <Paragraph variant='text' mt='8px' textAlign={{xs: 'center'}}>
        {body}
      </Paragraph>

      <StyledActionButton
        size="large"
        mt="24px"
        onClick={() => {
          onNavAction();
          closeModal();
        }}
      >
        {buttonText}
      </StyledActionButton>

      {!isAddendum && !isTeamMember && (
        <a
          href={BENJI_ZENDESK_ARTICLE}
          target="_blank"
          onClick={() => {
            closeModal();
          }}
        >
          <Button color='secondary' size='large' mt='16px' width='100%' >
            Learn more
          </Button>
        </a>
      )}
    </BlockAlertWrapper>
  );
};

export default ReferralsBlockedAlert;
