const IconOnlyWhite = () => (
  <svg
    width="26"
    height="26"
    viewBox="0 0 26 26"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.3267 25.5982C8.21372 25.5982 3.8178 22.5384 1.8623 18.1514L13.3339 9.81836L24.7886 18.1574C22.8317 22.5411 18.4376 25.5982 13.3267 25.5982ZM16.2916 18.1597H13.8685V15.3726H16.2916V18.1597ZM16.2916 22.0239H13.8685V19.2368H16.2916V22.0239ZM12.7918 22.0239H10.3686V19.2368H12.7918V22.0239ZM12.7918 18.1597H10.3686V15.3726H12.7918V18.1597Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M25.5951 15.6893C25.7776 14.8378 25.8756 13.9551 25.8756 13.049C25.8756 6.11838 20.2574 0.5 13.3266 0.5C6.39596 0.5 0.777588 6.11838 0.777588 13.049C0.777588 13.9527 0.875291 14.8335 1.05689 15.6831L13.3345 6.76304L25.5951 15.6893Z"
      fill="white"
    />
  </svg>
);

export default IconOnlyWhite;
