import { useCallback, useEffect, useState } from 'react';

import { theme } from '@hl-portals/constants';

export const useScreenSize = (initialWidth?: number) => {
  const [width, setWidth] = useState<number>(initialWidth || 0);

  const { breakpointsValue } = theme;
  const { sm, md } = breakpointsValue;

  const onResize = useCallback(() => {
    setWidth(window.innerWidth);
  }, []);

  useEffect(() => {
    setWidth(window.innerWidth);
    window.addEventListener('resize', onResize);
    return () => window.removeEventListener('resize', onResize);
  }, []);

  return {
    isMobile: width <= sm,
    isTablet: width > sm && width <= md,
    isDesktop: width > md - 1,
  };
};
