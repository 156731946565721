export const paymentMethods = [
  {
    id: 'card',
    name: 'Credit Card',
    description: '2.9% + 30 cents',
  },
  {
    id: 'us_bank_account',
    name: 'ACH (2-3 days)',
    description: '0.8% capped at $5',
  },
];
