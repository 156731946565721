import { colors, spacings } from '@homelight/particle-tokens';

import React, { useEffect } from 'react';

import {
  Box,
  Button,
  Divider,
  Icon,
  Paragraph,
  Radio,
  StatusLabel,
  useMultiStepContext,
} from '@hl-portals/ui';

import { formatCurrency } from '@hl-portals/helpers';

import { useUser } from '../../../../../contexts/Auth';
import { useComplianceEvents } from '../../../../../hooks/useComplianceEvents';
import { usePaymentDueActions } from '../../../../../hooks/usePaymentDueActions';
import { paymentMethods } from '../PaymentMethod/paymentMethods';
import { PaymentMethodProps } from '../types';
import {
  CloseLeadsContainer,
  Container,
  Footer,
  PaymentMethodContainer,
  PaymentMethodItemContainer,
  PriceContainer,
  SummaryContainer,
} from './styles';

const MAX_CLOSED_LEADS = 3;

const BackButton = (props: any) => {
  const { goBack } = useMultiStepContext();

  return (
    <Button
      type="button"
      color="tertiary"
      width="0px"
      onClick={goBack}
      size="xsmall"
      style={{
        paddingTop: '5px',
      }}
      {...props}
    >
      <Icon type="angleLeftBigBold" size={16} />
    </Button>
  );
};

export const MobilePaymentMethodModal = ({
  displayData,
  severity,
  paymentMethod,
  setPaymentMethod,
}: PaymentMethodProps) => {
  const { goNext } = useMultiStepContext();
  const { user } = useUser();
  const { recordComplianceView, recordComplianceAction } = useComplianceEvents({
    severity,
    agentId: user?.agent?.id,
    metadata: displayData.metadata,
    platform: 'mobile_web',
    screen: 'checkout_view',
  });

  useEffect(() => {
    recordComplianceView();
  }, []);

  const { title, paymentMethodsTitle, paymentMethodsSubTitle } =
    displayData?.modals.checkout;
  const { total, dueDate, fees } = displayData?.modals.paymentDue;

  const { content: closedLeads } = displayData?.modals.paymentDue.closedLeads;
  const { content: buttonLabel } = displayData?.modals.checkout.button;

  const handlePaymentMethodSelect = (methodId: string) => {
    setPaymentMethod(methodId);
  };

  const handleContinue = () => {
    recordComplianceAction('continue');
    goNext();
  };

  return (
    <Box flexDirection="column">
      <BackButton />
      <Container>
        <SummaryContainer>
          <Paragraph
            fontWeight="bold"
            marginBottom={`${spacings.spacingXxxs}px`}
          >
            {title}
          </Paragraph>

          <PriceContainer>
            <Paragraph
              color="cadmiumRed"
              variant="heading-2"
              marginBottom={`${spacings.spacingMicro}px`}
            >
              {formatCurrency({ value: parseInt(total) / 100 })}
            </Paragraph>

            <Paragraph variant="text-small" color="cadmiumRed">
              Due {dueDate}
            </Paragraph>

            {fees && parseInt(fees) > 0 && (
              <StatusLabel
                variant="error"
                display="inline-flex"
                mt={`${spacings.spacingNano}px`}
                paddingY={`${spacings.spacingNano}px`}
              >
                $ {fees} late fee added
              </StatusLabel>
            )}
          </PriceContainer>

          <CloseLeadsContainer>
            <Paragraph
              variant="text-small-bold"
              marginBottom={`${spacings.spacingNano}px`}
            >
              Closed leads
            </Paragraph>

            {closedLeads
              .slice(0, MAX_CLOSED_LEADS)
              .map((closedLead: string, idx: number) => (
                <Paragraph
                  key={idx}
                  variant="text-small"
                  marginBottom={`${spacings.spacingMicro}px`}
                  whiteSpace="nowrap"
                  overflow="hidden"
                  textOverflow="ellipsis"
                >
                  {closedLead}
                </Paragraph>
              ))}
          </CloseLeadsContainer>
        </SummaryContainer>

        <Divider margin={`${spacings.spacingXs}px 0`} />

        <PaymentMethodContainer>
          <Paragraph
            fontWeight="bold"
            marginBottom={`${spacings.spacingNano}px`}
          >
            {paymentMethodsTitle}
          </Paragraph>
          <Paragraph
            fontWeight="regular"
            marginBottom={`${spacings.spacingXxxs}px`}
          >
            {paymentMethodsSubTitle}
          </Paragraph>
          {paymentMethods.map((method, index) => (
            <PaymentMethodItemContainer key={index}>
              <Box flex={1}>
                <Box
                  flexDirection="column"
                  gap={`${spacings.spacingMicro}px`}
                  flex={1}
                >
                  <Paragraph>{method.name}</Paragraph>
                  <Paragraph color={colors.coolGray3}>
                    {method.description}
                  </Paragraph>
                </Box>
                <Box alignItems="center">
                  <Radio
                    name={method.id}
                    value={method.id}
                    checked={paymentMethod === method.id}
                    onChange={() => handlePaymentMethodSelect(method.id)}
                  />
                </Box>
              </Box>
              {index !== paymentMethods.length - 1 && (
                <Divider margin={`${spacings.spacingXxxs}px 0`} />
              )}
            </PaymentMethodItemContainer>
          ))}
          <Footer>
            <Button
              disabled={paymentMethod === null}
              width="100%"
              color="cta-action"
              size="large"
              onClick={handleContinue}
            >
              {buttonLabel}
            </Button>
          </Footer>
        </PaymentMethodContainer>
      </Container>
    </Box>
  );
};
