import RevSharePaymentSuccessPage from 'apps/agent-dashboard/pages/rev-share/payment-success';

import React, { useState } from 'react';
import { useForm } from 'react-hook-form';

import { useRouter } from 'next/router';

import { Box, MultiStep, Spinner } from '@hl-portals/ui';
import { MultiStepForm, useMultiStep } from '@hl-portals/ui';

import PaymentDueScreen from '../Collection/PaymentDueScreen';
import PaymentMethodScreen from '../Collection/PaymentMethodScreen';
import StripeCheckoutScreen from '../Collection/StripeCheckoutScreen';
import { ComplianceDisplayData } from '../transformers/UserCompliance/ComplianceDisplayData';
import { UserCompliance } from '../transformers/UserCompliance/types';

export const ComplianceModal = ({
  displayData,
  severity,
  type,
}: UserCompliance) => {
  const [paymentMethod, setPaymentMethod] = useState<string | null>(null);

  const methods = useForm();
  const router = useRouter();
  const multiStep = useMultiStep();
  const modalDisplayData = ComplianceDisplayData.create(displayData);

  if (!displayData) {
    return null;
  }

  const steps = [
    {
      title: 'PaymentDueScreen',
      component: (
        <PaymentDueScreen
          displayData={modalDisplayData}
          severity={severity}
          type={type}
        />
      ),
    },
    {
      title: 'PaymentMethodScreen',
      component: (
        <PaymentMethodScreen
          displayData={modalDisplayData}
          severity={severity}
          type={type}
          paymentMethod={paymentMethod}
          setPaymentMethod={setPaymentMethod}
        />
      ),
    },
    {
      title: 'CheckoutModal',
      component: (
        <StripeCheckoutScreen
          severity={severity}
          displayData={modalDisplayData}
          paymentMethod={paymentMethod}
        />
      ),
    },
    {
      title: 'PaymentSuccess',
      component: (
        <RevSharePaymentSuccessPage
          severity={severity}
          displayData={modalDisplayData}
        />
      ),
    },
  ];

  return (
    <Box>
      {router ? (
        <MultiStepForm
          methods={methods}
          onSubmit={() => {}}
          multiStepProps={multiStep}
        >
          {steps.map(({ title, component: Component }) => (
            <MultiStep.Step key={title} name={title}>
              {Component}
            </MultiStep.Step>
          ))}
        </MultiStepForm>
      ) : (
        <Spinner xl />
      )}
    </Box>
  );
};
