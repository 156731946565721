import React from 'react';

import { Colors, theme } from '@hl-portals/constants';

import { BoxTypes } from '../Box';
import { Icon, IconTypeProp } from '../Icon';
import Spinner from '../Spinner';
import {
  AngleLeftButtonIcon,
  AngleRightButtonIcon,
  SendButtonIcon,
} from './icons';
import { ButtonIconWrapper } from './styles';

const LEGACY_BUTTON_ICONS = {
  send: SendButtonIcon,
  'angle-left': AngleLeftButtonIcon,
  'angle-right': AngleRightButtonIcon,
  'angle-down': () => <Icon type="angleDown" />,
};

type LegacyButtonIcons = keyof typeof LEGACY_BUTTON_ICONS;

type ButtonIconProps = {
  icon: IconTypeProp | LegacyButtonIcons;
  size?: number;
  fill?: Colors;
  onClick?: (e?: React.MouseEvent<HTMLButtonElement>) => void;
  isLoading?: boolean;
  disabled?: boolean;
  title?: string;
} & BoxTypes;

const ButtonIcon = ({
  icon,
  size = 24,
  fill = 'darkBlue',
  onClick,
  isLoading,
  disabled,
  ...otherProps
}: ButtonIconProps): React.ReactElement => {
  const isLegacyIcon = icon in LEGACY_BUTTON_ICONS;

  return (
    <ButtonIconWrapper
      type="button"
      size={size}
      fill={theme.colors[fill]}
      onClick={onClick}
      disabled={disabled}
      {...otherProps}
    >
      {isLoading ? (
        <Spinner xl />
      ) : isLegacyIcon ? (
        React.createElement(LEGACY_BUTTON_ICONS[icon as LegacyButtonIcons])
      ) : (
        <Icon type={icon as IconTypeProp} size={size} fill={fill} />
      )}
    </ButtonIconWrapper>
  );
};

export default ButtonIcon;
