import { useScreenSize } from '@hl-portals/hooks';

import { MobilePaymentMethodModal } from '../Modals/MobilePaymentMethod';
import { PaymentMethodModal } from '../Modals/PaymentMethod';
import { PaymentMethodProps } from '../Modals/types';

const PaymentMethodScreen = ({
  displayData,
  severity,
  type,
  paymentMethod,
  setPaymentMethod,
}: PaymentMethodProps) => {
  const { isDesktop } = useScreenSize(window.innerWidth);

  const ComplianceToDisplay = isDesktop
    ? PaymentMethodModal
    : MobilePaymentMethodModal;

  return (
    <ComplianceToDisplay
      displayData={displayData}
      severity={severity}
      paymentMethod={paymentMethod}
      setPaymentMethod={setPaymentMethod}
      type={type}
    />
  );
};

export default PaymentMethodScreen;
