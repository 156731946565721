import * as Sentry from '@sentry/nextjs';
import axios, { AxiosRequestConfig } from 'axios';
import Jsona from 'jsona';
import { isPublicOrTokenizedPage } from 'packages/libs/auth/src/utils';

import { getSession } from 'next-auth/react';

import { signOut } from '@hl-portals/libs/auth';

import config from './config';

const dataFormatter = new Jsona();

const stagingAuth = {
  username: config.basicAuthUsername,
  password: config.basicAuthPassword,
};

const withBasicAuth =
  config.environmentName === 'staging' &&
  stagingAuth.username &&
  stagingAuth.password;

const enhancedAxios = async <T = any>({
  translateJsona,
  bypassAuth,
  basicAuth,
  formatter,
  token,
  ...request
}: AxiosRequestConfig & {
  translateJsona?: boolean;
  bypassAuth?: boolean;
  basicAuth?: boolean;
  formatter?: (data: T) => any;
  token?: string;
}): Promise<T & { _httpStatus?: number }> => {
  const session = await getSession();
  // @ts-ignore
  const nextAuthToken = (session as SessionWithToken)?.accessToken;
  const expressApprovalToken = localStorage.getItem(
    'EXPRESS_APPROVAL_CLIENT_TOKEN'
  );

  const headers = {
    'Content-Type': 'application/json',
    ...(expressApprovalToken || nextAuthToken
      ? {
          Authorization: `Bearer ${
            token || expressApprovalToken || nextAuthToken
          }`,
        }
      : {}),
  };
  // @ts-ignore
  return axios
    .request<T>({
      baseURL: config.hapiHost,
      withCredentials: true,
      auth: basicAuth && withBasicAuth ? stagingAuth : undefined,
      ...request,
      headers: { ...headers, ...(request.headers ?? {}) },
    })
    .then(({ data, status }) => {
      if (request.responseType === 'blob') {
        return {
          data,
          _httpStatus: status,
        };
      }

      if (translateJsona) {
        const deserializedJsona = data ? dataFormatter.deserialize(data) : data;
        const formatted = formatter
          ? formatter(deserializedJsona as T)
          : deserializedJsona;

        const { meta } = (data as unknown as any) ?? {};

        if (meta) {
          return {
            data: formatted,
            _httpStatus: status,
            meta,
          } as unknown as T;
        }

        return formatted as T;
      }

      if (formatter) {
        return formatter(data);
      }

      return { ...data, _httpStatus: status };
    })
    .catch(async (e) => {
      if (bypassAuth) return Promise.reject(e);

      const responseStatus = e?.response?.status || 500;

      if (responseStatus >= 500) {
        Sentry.captureException(e);
      }

      if (
        responseStatus === 401 &&
        !isPublicOrTokenizedPage(document?.location?.pathname)
      ) {
        await signOut();
      }

      return Promise.reject(e);
    });
};

export default enhancedAxios;
