import React, { useMemo } from 'react';

import { useRouter } from 'next/router';

import { Banner, Box } from '@hl-portals/ui';

import { theme } from '@hl-portals/constants';

import { stringMatchesAny } from '@hl-portals/helpers';

import { useUser } from '../../../contexts/Auth';
import {
  restrictionPageExclusions,
  warningPageExclusions,
} from '../SignInAlerts';
import { ComplianceBanner } from '../UserCompliance/Banners/ComplianceBanner';
import DynamicBanner from './DynamicBanner';
import ReferralsBlockedBanner from './ReferralsBlockedBanner';

type AlertBannerProps = {
  isElite: boolean;
};

const AlertBanner = ({ isElite }: AlertBannerProps): React.ReactElement => {
  const { featureFlags, user } = useUser();
  const router = useRouter();
  const AlertMap = useMemo(
    () => ({
      'referrals-blocked-banner': {
        dismissed: false,
        Component: ReferralsBlockedBanner,
        onDismiss: undefined,
        type: 'error',
      },
      'dynamic-banner': {
        dismissed: false,
        Component: DynamicBanner,
        onDismiss: undefined,
        type: 'error',
      },
      'addendum-banner': {
        dismissed: false,
        Component: DynamicBanner,
        onDismiss: undefined,
        type: 'error',
      },
      'compliance-awareness': {
        dismissed: false,
        Component: ComplianceBanner,
        onDismiss: undefined,
        type: 'warning',
      },
      'compliance-restriction': {
        dismissed: false,
        Component: ComplianceBanner,
        onDismiss: undefined,
        type: 'error',
      },
    }),
    []
  );

  const agentAgreementChanges = useMemo(
    () => user.agent?.agent_agreement_changes,
    [user]
  );

  const agentComplianceRequirement = useMemo(
    () => user.agent?.compliance_requirement,
    [user]
  );

  const key = useMemo(() => {
    const isWarningPage = !stringMatchesAny(
      router.pathname,
      warningPageExclusions,
      { strict: false }
    );

    const isRestrictionPage = !stringMatchesAny(
      router.pathname,
      restrictionPageExclusions,
      { strict: false }
    );

    // Compliance awareness period
    if (
      isRestrictionPage &&
      agentComplianceRequirement?.type === 'collection' &&
      agentComplianceRequirement?.severity === 'warning'
    ) {
      return 'compliance-awareness';
    }

    // compliance restriction period
    if (
      agentComplianceRequirement?.type === 'collection' &&
      agentComplianceRequirement?.severity === 'restriction'
    ) {
      return 'compliance-restriction';
    }

    if (
      !featureFlags.display_agreement_changes &&
      featureFlags.show_referrals_blocked_warning &&
      isWarningPage &&
      sessionStorage.getItem('hellosign_agent_agreement_signed') !== 'true'
    ) {
      return 'referrals-blocked-banner';
    }

    if (
      featureFlags.display_agreement_changes &&
      isWarningPage &&
      sessionStorage.getItem('hellosign_agent_agreement_signed') !== 'true'
    ) {
      return 'dynamic-banner';
    }

    // addendum only shown if not displaying full agreement changes
    if (
      !featureFlags.display_agreement_changes &&
      featureFlags.display_addendum_changes &&
      isWarningPage &&
      agentAgreementChanges?.agreement_type === 'addendum'
    ) {
      return 'addendum-banner';
    }
  }, [
    featureFlags,
    router.pathname,
    agentAgreementChanges,
    agentComplianceRequirement,
  ]);

  if (!AlertMap[key]) {
    return null;
  }

  const { dismissed, Component, onDismiss, type } = AlertMap[key];
  if (!Component || dismissed) {
    return null;
  }

  const backgroundColor = () => {
    switch (type) {
      case 'error':
        return theme.colors.red2;
      case 'warning':
        return theme.colors.yellow200;
      default:
        return null;
    }
  };

  return (
    <Banner
      onDismiss={onDismiss}
      isElite={isElite}
      data-test="alert-banner"
      bgcolor={backgroundColor()}
    >
      <Box
        justifyContent="space-between"
        flexDirection={{ xs: 'column', md: 'row' }}
        alignItems={{ xs: 'flex-start', md: 'center' }}
        width="100%"
      >
        <Component
          isElite={isElite}
          onDismiss={onDismiss}
          agentAgreementChanges={agentAgreementChanges}
          userCompliance={agentComplianceRequirement}
        />
      </Box>
    </Banner>
  );
};

export default AlertBanner;
