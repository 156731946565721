import { red } from '@mui/material/colors';
import { useUser } from 'apps/agent-dashboard/contexts/Auth';
import styled, { css } from 'styled-components';
import { record } from 'zod';

import { useEffect } from 'react';

import { Box, Button, Icon, Paragraph } from '@hl-portals/ui';

import { useScreenSize } from '@hl-portals/hooks';

import { useComplianceEvents } from '../../hooks/useComplianceEvents';

const MobileWrapper = styled(Box)`
  ${({ theme }) => css`
    position: fixed;
    bottom: 0;
    flex-direction: column;
    padding: 16px 16px 28px 16px;
    background: ${theme.colors.white};
    left: 0;
    right: 0;
    z-index: 1;
    border-top: solid 1px ${theme.colors.coolGray4};
  `}
`;

const RevSharePaymentSuccessPage = ({
  severity,
  displayData,
}: {
  severity?: string;
  displayData?: any;
}): React.ReactElement => {
  const { user } = useUser();
  const { isMobile } = useScreenSize();
  const { recordComplianceView, recordComplianceAction } = useComplianceEvents({
    severity,
    agentId: user?.agent?.id,
    metadata: displayData.metadata,
    platform: isMobile ? 'mobile_web' : 'desktop_web',
    screen: 'success_view',
  });

  useEffect(() => {
    recordComplianceView();
  }, []);

  useEffect(() => {
    const timeout = setTimeout(() => {
      recordComplianceAction('success_auto_redirection');

      window.location.href = '/dashboard';
    }, 5000);
    return () => clearTimeout(timeout);
  }, []);

  return (
    <>
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        gap="40px"
        padding={{ xs: '150px 24px 130px', md: '117px 150px' }}
        bgcolor="white"
        overflow-y={{ xs: 'scroll', md: 'hidden' }}
      >
        <InfoSection />
        {isMobile ? (
          <MobileWrapper>
            <ButtonSection recordComplianceAction={recordComplianceAction} />
          </MobileWrapper>
        ) : (
          <ButtonSection recordComplianceAction={recordComplianceAction} />
        )}
      </Box>
    </>
  );
};

const InfoSection = () => (
  <Box
    display="flex"
    flexDirection="column"
    justifyContent="center"
    alignItems="center"
    gap="56px"
    alignSelf="stretch"
  >
    <SuccessText />
  </Box>
);

const ButtonSection = ({
  recordComplianceAction,
}: {
  recordComplianceAction: (action: string) => void;
}) => {
  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      width="100%"
    >
      <Button
        size="large"
        width="100%"
        maxWidth="425px"
        onClick={() => {
          recordComplianceAction('success_redirect');

          window.location.href = '/dashboard';
        }}
      >
        Start Claiming
      </Button>
    </Box>
  );
};

const SuccessText = () => {
  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      gap="16px"
      alignSelf="stretch"
    >
      <SuccessIcon />
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        gap="8px"
      >
        <Paragraph color="darkBlue" variant="heading-3">
          Thank you!
        </Paragraph>
        <Paragraph maxWidth="476px" textAlign="center">
          Your payment is accepted! You'll be automatically redirected to the
          dashboard or click the button below.
        </Paragraph>
      </Box>
    </Box>
  );
};

const SuccessIcon = () => (
  <Box
    width="104px"
    height="104px"
    padding="16px"
    display="flex"
    justifyContent="center"
    alignItems="center"
    borderRadius="9999px"
    background="var(--Green-Green-300, #CEF0E4)"
    boxShadow="0px 3px 16px 0px rgba(108, 129, 171, 0.20)"
  >
    <Box
      width="72px"
      height="72px"
      display="flex"
      justifyContent="center"
      alignItems="center"
      borderRadius="42px"
      background="var(--Green-Green-200, #E6F7F1)"
    >
      <Icon type="checkCircle" size={50} fill="confirm" />
    </Box>
  </Box>
);

export default RevSharePaymentSuccessPage;
