import { SpacingProps, spacing as muiSpacing } from '@mui/system';
import styled, { css } from 'styled-components';

interface ResultItemProps {
  selected: boolean;
  disabled?: boolean;
  variant: 'bottom' | 'top';
}

export const ResultItem = styled.div<ResultItemProps & SpacingProps>`
  ${({ theme, selected, variant, disabled }) => css`
    ${disabled &&
    `
      color: ${theme.colors.gray600};
      pointer-events: none;
      font-size: 14px;
    `}
    position: relative;
    &:hover {
      cursor: pointer;
      background-color: ${selected && !disabled
        ? `${theme.colors.electricBlue}14`
        : 'transparent'};
    }

    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: transparent;
      pointer-events: none;
    }
    ${variant === 'bottom'
      ? css`
          &:first-child::before {
            border-top-left-radius: 8px;
            border-top-right-radius: 8px;
          }
        `
      : css`
          &:last-child::before {
            border-bottom-left-radius: 8px;
            border-bottom-right-radius: 8px;
          }
        `}
  `}
  ${muiSpacing}
`;

interface ResultWrapperProps {
  isOpen: boolean;
  variant: 'top' | 'bottom';
}

export const ResultWrapper = styled.div<ResultWrapperProps>`
  ${({ theme, isOpen, variant }) => css`
    overflow-y: hidden;
    padding: 0 4px 4px 4px;
    margin: 0 -4px;
    display: ${isOpen ? 'flex' : 'none'};
    position: absolute;
    z-index: 999;
    ${variant === 'bottom' ? 'top' : 'bottom'}: calc(100% - 3px);
    left: 0;
    right: 0;
    > div {
      display: flex;
      width: 100%;
      max-height: 240px;
      overflow: auto;
      flex-direction: column;
      background: ${theme.colors.white};
      border: 1px solid ${theme.colors.coolGray4};
      border-top: 0;
      box-sizing: border-box;
      border-radius: 8px;
      margin: ${variant === 'bottom' ? '8px 0 0' : '0 0 8px'};
      box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.3);
    }
  `}
`;

export const HoverableBox = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  cursor: pointer;
  &:hover {
    background-color: rgba(0, 0, 0, 0.04);
  }
`;
