import { http } from '@hl-portals/libs/http';

import { RECORD_USER_EVENT_PATH } from '@hl-portals/constants';

import { AgentEvent } from './agent-event-types';
import { ClientEvent } from './client-event-types';
import { LeadSubmissionEvent } from './lead-submission-event-types';
import { PageEvent } from './page-event-type';

type UseUserEventProps =
  | AgentEvent
  | ClientEvent
  | PageEvent
  | LeadSubmissionEvent;

interface UseUserEvent {
  recordUserEvent: ({
    source,
    event_action,
    result,
    agent_lead_token,
    agent_slug,
  }: UseUserEventProps) => Promise<void>;
}

const useUserEvents = (): UseUserEvent => {
  const recordUserEvent = ({
    source,
    event_action,
    result = {},
    agent_lead_token,
    agent_slug,
    includeSource = true,
  }: UseUserEventProps) => {
    const resultPayload = {
      platform: 'web',
      ...result,
    };

    return http.private({
      url: RECORD_USER_EVENT_PATH,
      method: 'POST',
      bypassAuth: true,
      data: {
        event_action: includeSource
          ? `${source}_${event_action}`
          : event_action,
        result: resultPayload,
        agent_lead_token,
        agent_slug,
      },
    });
  };

  return {
    recordUserEvent,
  };
};

export default useUserEvents;
