import { Controller, useFormContext } from 'react-hook-form';

import { BoxTypes } from '../../Box';
import InputGroup, { InputGroupProps } from '../../InputGroup';
import { Select, SelectProps } from '../../Select';

type SelectInputProps = {
  name?: string;
  label?: string;
  options: SelectProps['options'];
  containerProps?: BoxTypes & InputGroupProps;
  onChange?: (value: string) => void;
  value?: string;
  loadingOptions?: boolean;
  placeholder?: string;
};

export const SelectInput = ({
  name,
  value: selectedValue,
  label,
  options,
  containerProps,
  onChange: customOnChange,
  loadingOptions,
  placeholder = 'Select',
}: SelectInputProps) => {
  const { control } = useFormContext();

  if (name) {
    return (
      <InputGroup label={label} {...containerProps}>
        <Controller
          control={control}
          name={name}
          rules={{ required: true }}
          render={({ field: { onChange, value } }) => (
            <Select
              width="100%"
              height="48px"
              customSelectedItemCss={{ width: '100%' }}
              onChange={(val) => {
                if (customOnChange) {
                  customOnChange(val);
                }
                onChange(val);
              }}
              selectedValue={value}
              variant={{ xs: 'top', md: 'bottom' }}
              options={options}
              placeholder={loadingOptions ? 'Loading...' : placeholder}
              disabled={loadingOptions}
            />
          )}
        />
      </InputGroup>
    );
  }

  return (
    <InputGroup label={label} {...containerProps}>
      <Select
        width="100%"
        height="48px"
        customSelectedItemCss={{ width: '100%' }}
        onChange={(val) => {
          if (customOnChange) {
            customOnChange(val);
          }
        }}
        selectedValue={selectedValue}
        variant={{ xs: 'top', md: 'bottom' }}
        options={options}
        placeholder={placeholder}
      />
    </InputGroup>
  );
};
