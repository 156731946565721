export const SENTRY_SCOPE = {
  FINGERPRINTS: {
    METHOD: {
      GET: 'GET',
      POST: 'POST',
      PUT: 'PUT',
      PATCH: 'PATCH',
      DELETE: 'DELETE',
    },
    API_ERROR: 'api-error',
    PUBLIC_EVENT_ERROR: 'public-event-error',
    GLOBAL_BOUNDARY_ERROR: 'global-boundary-error',
    UI_ERROR: 'ui-error',
    DISBURSEMENT_SERVICE: {
      ERROR: 'disbursement-service-error',
      INSIGHTS: {
        LEADS: '/api/disbursement-service/leads/:lead_id/insights',
        LOAN_OFFICER:
          '/api/disbursement-service/loan-officers/:loan_officer_id/insights',
      },
      PAYMENTS_HISTORY:
        '/api/disbursement-service/loan-officers/:loan_officer_id/payments-history',
      TROLLEY: {
        HAS_PAYMENT_METHOD:
          '/api/disbursement-service/loan-officers/:loan_officer_id/trolley-payment-config?fields[trolley_config]=can_receive_payments',
        GET_IFRAME_URL:
          '/api/disbursement-service/loan-officers/:loan_officer_id/trolley-payment-config?fields[trolley_config]=iframe_url',
      },
    },
    LEAD_DATA_SERVICE: {
      GENERATED_DOCUMENT: {
        ERROR: 'lead-data-service-generated-document-error',
        DOWNLOAD_PDF:
          '/api/lead-data-service/v3/leads/:lead_id/generated-documents/:generated_document_id/download-pdf',
      },
    },
    LOAN_OFFICER_DATA_SERVICE: {
      ERROR: 'loan-officer-data-service-error',
      SEARCH_LENDER:
        '/api/loan-officer-data-service/lenders?filter[name]=:search-term',
      UPDATE_LOAN_OFFICER:
        '/api/loan-officer-data-service/loan-officers/:loan_officer_id',
      CREATE_LENDER: '/api/loan-officer-data-service/loan-officers',
    },
    TAGS: {
      FEATURE: 'equity-loan-officer-portal-payments',
    },
  },
  LOAN_OFFICER_PORTAL: {
    TAGS: {
      FEATURE: 'equity-loan-officer-portal-payments',
    },
  },
  UPLOAD: {
    ERROR: 'upload-error',
  },
  LEAD_SUBMISSION: {},
} as const;
