import { useUser } from 'apps/agent-dashboard/contexts/Auth';

import React from 'react';

import { useRouter } from 'next/router';

import { Icon, Paragraph } from '@hl-portals/ui';

import { theme } from '@hl-portals/constants';

import { useUserEvents } from '@hl-portals/hooks';

import { ComplianceDisplayData } from '../transformers/UserCompliance/ComplianceDisplayData';
import { BannerCta } from './styles';

export const PAYMENT_DUE_VALUE = 'paymentDue';

type ComplianceBannerProps = {
  userCompliance: UserCompliance;
};

export const ComplianceBanner = ({ userCompliance }: ComplianceBannerProps) => {
  const router = useRouter();
  const { severity, display_data } = userCompliance;
  const bannerDisplayData = ComplianceDisplayData.create(display_data);
  const { recordUserEvent } = useUserEvents();
  const { user } = useUser();

  if (!bannerDisplayData) {
    return null;
  }

  const buttonColor =
    severity === 'restriction' ? 'urgent-inverted' : 'warning';
  const textColor = severity === 'restriction' ? 'red700' : '#BB8E00';
  const iconColor =
    severity === 'restriction' ? theme.colors.red700 : '#BB8E00';

  const isTeamMember =
    bannerDisplayData.metadata?.content_type === 'team_member' ||
    bannerDisplayData.metadata?.contentType === 'team_member';

  const { content: body } = bannerDisplayData?.banners.dynamicBanner;

  if (isTeamMember) {
    return (
      <Paragraph variant="text-small" color={textColor} lineHeight="150%">
        {body}
      </Paragraph>
    );
  }

  const { content: buttonLabel } =
    bannerDisplayData.banners.dynamicBanner.button;

  const onButtonClick = async () => {
    await recordUserEvent({
      source: 'agent_dashboard',
      event_action: 'compliance_banner_button_click',
      includeSource: false,
      result: {
        platform: 'web',
        agent_id: user.agent?.id,
      },
    });

    window.sessionStorage.setItem('compliance-requirement', 'false');
    await router.replace(`/dashboard`);
    location.reload();
  };

  return (
    <>
      <Paragraph
        display="inline-flex"
        variant="text-small"
        color={textColor}
        lineHeight="150%"
      >
        <Icon type="info" mr={{ xs: '16px', md: '16px' }} fill={iconColor} />
        {body}
      </Paragraph>

      <BannerCta
        onClick={onButtonClick}
        mt={{ xs: '16px', md: '0px' }}
        width={{ xs: '100%', md: 'auto' }}
        whiteSpace="nowrap"
        size={{ xs: 'medium', md: 'small' }}
        color={buttonColor}
      >
        {buttonLabel}
      </BannerCta>
    </>
  );
};
