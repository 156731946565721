import { useState } from 'react';

import { Icon } from '../../Icon';
import { TextInput, TextInputProps } from '../text-input';

export const PasswordInput = (props: TextInputProps) => {
  const [showPassword, setShowPassword] = useState(false);

  return (
    <TextInput
      type={showPassword ? 'text' : 'password'}
      right={
        <Icon
          type={showPassword ? 'eyeSlash' : 'eye'}
          onClick={() => setShowPassword((prev) => !prev)}
        />
      }
      {...props}
    />
  );
};
