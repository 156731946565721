import {
  BordersProps,
  PaletteProps,
  SizingProps,
  SpacingProps,
} from '@mui/system';

import { useEffect, useState } from 'react';

import { Box } from '../Box';
import { Icon, IconTypeProp } from '../Icon';
import { AccordionButton, AccordionContent, AccordionIcon } from './styles';

export type AccordionProps = {
  children?: React.ReactNode;
  label?: React.ReactNode;
  icon?: IconTypeProp;
  iconOpen?: IconTypeProp;
  iconPosition?: 'left' | 'right';
  iconColor?: string;
  showDivider?: boolean;
  fontColor?: string;
  fontWeight?: string;
  onOpen?: () => void;
  isOpen?: boolean;
  openOnMount?: boolean;
  enableFullAreaToggle?: boolean;
};

const Accordion = ({
  label,
  children,
  icon = 'angleDown',
  iconOpen = 'angleUp',
  iconPosition = 'left',
  iconColor = '#273653',
  showDivider = false,
  onOpen,
  isOpen = false,
  openOnMount = false,
  fontColor = '#273653',
  fontWeight = '600',
  enableFullAreaToggle = false,
  ...otherProps
}: AccordionProps &
  SizingProps &
  SpacingProps &
  BordersProps &
  PaletteProps): React.ReactElement => {
  const [open, setOpen] = useState(isOpen);

  useEffect(() => {
    if (open && onOpen) {
      onOpen();
    }
  }, [open, onOpen]);

  useEffect(() => {
    if (openOnMount) {
      setOpen(true);
    }
  }, []);

  return (
    <Box
      width={1}
      pb={showDivider ? '16px' : '0px'}
      flexDirection="column"
      borderBottom={showDivider ? '1px solid #DBDFE6' : 'none'}
      onClick={enableFullAreaToggle ? () => setOpen(!open) : () => {}}
      style={{
        cursor: enableFullAreaToggle ? 'pointer' : 'auto',
      }}
      {...otherProps}
    >
      <AccordionButton
        selected={open}
        onClick={enableFullAreaToggle ? () => {} : setOpen}
        iconPosition={iconPosition}
        fontColor={fontColor}
        fontWeight={fontWeight}
      >
        {iconPosition === 'left' && (
          <AccordionIcon type="arrowTriangleRight" open={open} size={12} />
        )}
        {label}
        {iconPosition === 'right' && (
          <Icon
            type={open ? iconOpen ?? 'angleUp' : icon}
            color={iconColor}
            fill={iconColor}
          />
        )}
      </AccordionButton>
      <AccordionContent
        open={open}
        iconPosition={iconPosition}
        enableFullAreaToggle={enableFullAreaToggle}
      >
        {children}
      </AccordionContent>
    </Box>
  );
};

export default Accordion;
