import { useUser } from 'apps/agent-dashboard/contexts/Auth';

import { useEffect } from 'react';
import React from 'react';

import { Paragraph } from '@hl-portals/ui';

import { useUserEvents } from '@hl-portals/hooks';

import { ComplianceModalProps } from '../types';
import { RestrictedImageWrapper } from './styles';

export const TeamMemberModal = ({
  displayData,
  severity,
}: ComplianceModalProps) => {
  const { title, body } = displayData?.modals.dynamicModal;
  const severityIllustration =
    severity === 'restriction'
      ? '/illustrations/restricted.png'
      : '/illustrations/awareness.png';

  const { recordUserEvent } = useUserEvents();
  const { user } = useUser();

  useEffect(() => {
    const action =
      severity === 'restriction'
        ? 'team_member_compliance_restriction_view'
        : 'team_member_compliance_awareness_view';

    recordUserEvent({
      source: 'agent_dashboard',
      event_action: action,
      includeSource: false,
      result: {
        platform: 'web',
        agent_id: user.agent?.id,
      },
    });
  });

  return (
    <>
      <RestrictedImageWrapper>
        <img alt="severity" src={severityIllustration} />
      </RestrictedImageWrapper>

      <Paragraph
        variant="heading-4"
        textAlign={{ xs: 'center' }}
        data-test="claim-restricted-title"
      >
        {title}
      </Paragraph>

      <Paragraph
        variant="text"
        mt="8px"
        textAlign={{ xs: 'center' }}
        pb={2}
        paddingX={2}
        maxWidth={540}
      >
        {body}
      </Paragraph>
    </>
  );
};
