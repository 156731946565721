import { colors, spacings } from '@homelight/particle-tokens';

import React from 'react';

import { Box, Icon, Paragraph } from '@hl-portals/ui';

type NoticeProp = {
  title: string;
  subtitle: string;
  faded?: boolean;
  finalNotice?: boolean;
};

export const Notice = ({ title, subtitle, faded, finalNotice }: NoticeProp) => {
  const color = faded ? colors.coolGray2 : colors.darkBlue;

  return (
    <Box flexDirection="row" marginBottom={`${spacings.spacingXxxs}px`}>
      <Box flexDirection="column" mr={`${spacings.spacingSub}px`}>
        <Icon type="calendarSolid" fill={color} size={24} />
        {!finalNotice && (
          <Icon
            type="verticalLine"
            justifyContent="center"
            flex={1}
            heightAuto
          />
        )}
      </Box>

      <Box flexDirection="column">
        <Paragraph
          fontSize={{ xs: '14px' }}
          color={color}
          mt={{ lg: `${spacings.spacingMicro}px` }}
          mb={`${spacings.spacingMicro}px`}
          fontWeight="700"
        >
          {title}
        </Paragraph>

        <Paragraph fontSize={{ xs: '14px' }} color={color}>
          {subtitle}
        </Paragraph>
      </Box>
    </Box>
  );
};
