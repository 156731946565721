import styled, { css } from 'styled-components';

import { Button } from '@hl-portals/ui';

import { media } from '@hl-portals/helpers';

export const ActionButton = styled(Button)`
  width: 100%;
  height: 3rem;
  line-height: 1.25rem;
  font-size: 1rem;
  border-radius: 0.375rem;
`;

export const CancelButton = styled(ActionButton)`
  ${({ theme }) => css`
    background-color: ${theme.colors.white};
    border: solid 1px ${theme.colors.electricBlue};
    color: ${theme.colors.electricBlue};

    &:hover {
      background-color: ${theme.colors.lightBlue};
    }
  `}
`;

export const DeclineButton = styled(CancelButton)`
  ${({ theme }) => css`
    border: none;
    font-weight: 600;
    text-decoration-line: underline;
    text-decoration-thickness: 0.7px;
    &:focus,
    &:hover {
      background-color: unset;
      color: ${theme.colors.electricBlueHover};
    }

    ${media.shortPhone`
      height: 36px;
    `}
    ${media.tablet`
      order: 1;
    `}
  `}
`;

export const OrangeButton = styled(ActionButton)`
  ${({ theme }) => css`
    background-color: ${theme.colors.orange500};
    &:hover,
    &:focus {
      background-color: ${theme.colors.orange600};
    }
  `}
`;
