import {
  PAYMENT_CREATE_CHECKOUT_SESSION,
  PAYMENT_FETCH_CHECKOUT_SESSION_STATUS,
  PAYMENT_FINALIZE_INVOICE,
  PAYMENT_INVOICE_DISPLAY_DATA,
  PAYMENT_SUPPORT_TASK,
} from '@hl-portals/constants';

import { enhancedAxios } from '@hl-portals/helpers';

interface PaymentActions {
  finalizeInvoice: () => Promise<any>;
  createSupportTask: () => Promise<any>;
  invoiceDisplayData: () => Promise<any>;
  createCheckoutSession: (paymentMethod: string) => Promise<any>;
  createCheckoutSessionString: () => Promise<string>;
  fetchCheckoutSessionStatus: (sessionId: string) => Promise<any>;
}

export const usePayment = (agentId: string): PaymentActions => {
  const finalizeInvoice = async () => {
    const payload = await enhancedAxios({
      method: 'POST',
      url: PAYMENT_FINALIZE_INVOICE(agentId),
      data: {},
    });

    return payload;
  };

  const createSupportTask = async () => {
    const payload = await enhancedAxios({
      method: 'POST',
      url: PAYMENT_SUPPORT_TASK(agentId),
      data: {},
    });

    return payload;
  };

  const invoiceDisplayData = async () => {
    const payload = await enhancedAxios({
      method: 'GET',
      url: PAYMENT_INVOICE_DISPLAY_DATA(agentId),
      data: {},
    });

    return payload;
  };

  const createCheckoutSession = async (paymentMethod: string) => {
    const payload = await enhancedAxios({
      method: 'POST',
      url: PAYMENT_CREATE_CHECKOUT_SESSION(agentId),
      data: { payment_method: paymentMethod },
    });

    return payload;
  };

  const createCheckoutSessionString = async () => {
    const payload = await enhancedAxios({
      method: 'POST',
      url: PAYMENT_CREATE_CHECKOUT_SESSION(agentId),
      data: {},
    });

    return payload.client_secret;
  };

  const fetchCheckoutSessionStatus = async (sessionId: string) => {
    const payload = await enhancedAxios({
      method: 'GET',
      url: PAYMENT_FETCH_CHECKOUT_SESSION_STATUS(agentId, sessionId),
      data: {},
    });

    return payload;
  };

  return {
    finalizeInvoice,
    createSupportTask,
    invoiceDisplayData,
    createCheckoutSession,
    fetchCheckoutSessionStatus,
    createCheckoutSessionString,
  };
};
