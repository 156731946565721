import React from 'react';

import { useRouter } from 'next/router';

import styled from 'styled-components';

import { media } from '@hl-portals/helpers';
import { Paragraph } from '@hl-portals/ui';

import { AlertLink, NewAlertButton } from './styles';
import { ACCEPT_ADDENDUM_URL_PARAM } from '../../../constants/agreement';

const BannerCta = styled(NewAlertButton)`
  ${({ theme }) => `
    color: ${theme.colors.red700};
    font-size: 1rem;
    font-weight: 600;
  `}
  ${media.desktop`
    margin-left: 1.5rem;
  `}
`;

type DynamicBannerProps = {
  onDismiss: () => void;
  agentAgreementChanges: AgentAgreementChangesType;
};

const DynamicBanner = ({
  onDismiss = () => {},
  agentAgreementChanges,
}: DynamicBannerProps): React.ReactElement => {
  const router = useRouter();

  let navTarget = '/settings/referral-agreement';
  let buttonText = 'Sign referral agreement';
  const isTeamMember = agentAgreementChanges?.content_type === 'team_member';

  if (agentAgreementChanges?.agreement_type === 'addendum') {
    navTarget = `/dashboard?${ACCEPT_ADDENDUM_URL_PARAM}`;
    buttonText = 'Review updated terms';
  }

  return (
    <>
      <Paragraph variant="text-small" color="red700" lineHeight="150%">
        {agentAgreementChanges.banner_copy}
        {!isTeamMember && (
          <AlertLink
            ml="5px"
            href={agentAgreementChanges.resource_url}
            target="_blank"
          >
            Learn more
          </AlertLink>
        )}
      </Paragraph>

      {!isTeamMember && (
        <BannerCta
          onClick={() => {
            onDismiss();
            router.replace(navTarget);
          }}
          mt={{ xs: '16px', md: '0px' }}
          width={{ xs: '100%', md: 'auto' }}
          whiteSpace="nowrap"
          size={{ xs: 'medium', md: 'small' }}
        >
          {buttonText}
        </BannerCta>
      )}
    </>
  );
};

export default DynamicBanner;
