import { ErrorBoundary } from 'react-error-boundary';

import { SENTRY_SCOPE } from '@hl-portals/constants';

import { useAlertSentry } from '@hl-portals/hooks';

import { ErrorFallbackPage } from '../ErrorFallbackPage';

const { FINGERPRINTS } = SENTRY_SCOPE;

export const GlobalErrorBoundary = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const { alertSentry } = useAlertSentry();
  return (
    <ErrorBoundary
      FallbackComponent={({
        resetErrorBoundary,
      }: {
        resetErrorBoundary: () => void;
      }) => <ErrorFallbackPage refetch={resetErrorBoundary} />}
      onError={(error: Error, info: { componentStack: string }) => {
        console.error('Global Error Caught:', error);
        alertSentry({
          error,
          fingerprints: [FINGERPRINTS.GLOBAL_BOUNDARY_ERROR],
          customTags: {
            component: '_app.tsx',
          },
          extra: {
            info,
            error,
          },
        });
      }}
    >
      {children}
    </ErrorBoundary>
  );
};
