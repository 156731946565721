import { v4 as uuidv4 } from 'uuid';

import { PUBLIC_EVENT_PATH, SENTRY_SCOPE } from '@hl-portals/constants';

import { useAlertSentry } from '@hl-portals/hooks';

const requestId = uuidv4();

export function usePublicEvent<Event>(source?: string) {
  const { alertSentry } = useAlertSentry();

  async function recordPublicEvent(event: Event = {} as Event) {
    const payload = {
      source,
      request_id: requestId,
      ...event,
    };

    const res = { status: null } as { status: null | number };

    try {
      const response = await fetch(PUBLIC_EVENT_PATH, {
        method: 'POST',
        headers: new Headers({
          'Content-Type': 'application/json',
          'X-Request-Id': requestId,
        }),
        body: JSON.stringify(payload),
      });

      if (!response.ok) {
        res.status = response.status;
        alertSentry({
          errorMessage: `Failed to send event to ${PUBLIC_EVENT_PATH}. Server returned ${response.status}`,
          fingerprints: [SENTRY_SCOPE.FINGERPRINTS.PUBLIC_EVENT_ERROR],
          customTags: {
            endpoint: PUBLIC_EVENT_PATH,
            requestId,
            status: res.status,
          },
          extra: {
            payload,
          },
        });
      }
    } catch (error) {
      alertSentry({
        error: error as Error,
        fingerprints: [SENTRY_SCOPE.FINGERPRINTS.PUBLIC_EVENT_ERROR],
        customTags: {
          endpoint: PUBLIC_EVENT_PATH,
          requestId,
          status: res.status,
        },
        extra: {
          payload,
        },
      });
    }
  }

  return { recordPublicEvent };
}
