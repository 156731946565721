import { Event, EventHint } from '@sentry/types';

export const filterUnhandledRejection = (event: Event, hint: EventHint) => {
  // Filter out UnhandledRejection errors that have no useful info
  if (event?.exception?.values?.length === 1) {
    const e = event.exception.values[0];
    if (
      e.type === 'UnhandledRejection' &&
      e.value?.startsWith('Non-Error promise rejection captured with value: ')
    ) {
      return null; // Drop the event
    }
  }
  return event;
};
