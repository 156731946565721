import {
  DisplayProps,
  FlexboxProps,
  PaletteProps,
  PositionsProps,
  SizingProps,
  SpacingProps,
  display,
  flexbox,
  palette,
  positions,
  sizing,
  spacing,
} from '@mui/system';
import styled, { css } from 'styled-components';

interface IconWrapperProps {
  size: number;
  fill: string;
  color: string;
  flip?: boolean;
  contrast?: boolean;
  heightAuto?: boolean;
}

type IconType = IconWrapperProps &
  DisplayProps &
  SpacingProps &
  SizingProps &
  FlexboxProps &
  PositionsProps &
  PaletteProps;

const IconWrapper = styled.div<IconType>`
  display: flex;
  align-items: center;

  ${display}
  ${spacing}
  ${sizing}
  ${flexbox}
  ${positions}
  ${palette}

  ${({ theme, fill, size, contrast, flip, onClick, color, heightAuto }) => {
    const defaultFill = contrast
      ? theme?.colors?.white
      : theme?.colors?.darkBlue;
    return css`
      svg {
        height: ${!heightAuto && `${size}px`};
        width: ${size}px;
        fill: ${fill ?? defaultFill};
        color: ${color ?? defaultFill};
        ${flip ? 'transform: scaleX(-1)' : ''}
      }

      circle {
        fill: ${fill ?? defaultFill};
      }

      ${onClick &&
      css`
        &:hover {
          cursor: pointer;
        }
      `}
    `;
  }}
`;

export default IconWrapper;
