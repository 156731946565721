import { spacings } from '@homelight/particle-tokens';
import { CheckoutProvider } from '@stripe/react-stripe-js';
import { PaymentElement, useCheckout } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { useUser } from 'apps/agent-dashboard/contexts/Auth';
import { useComplianceEvents } from 'apps/agent-dashboard/hooks/useComplianceEvents';
import { usePayment } from 'apps/agent-dashboard/hooks/usePayment';

import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';

import {
  Box,
  Button,
  Icon,
  Paragraph,
  Spinner,
  StatusLabel,
  useMultiStepContext,
} from '@hl-portals/ui';

import { config } from '@hl-portals/helpers';

import { StripeCheckoutProps } from '../types';
import {
  CloseLeadsContainer,
  Container,
  Footer,
  PaymentMethodContainer,
  PriceContainer,
} from './styles';

const BackButton = (props: any) => {
  const { goBack } = useMultiStepContext();

  return (
    <Button
      type="button"
      color="tertiary"
      width="0px"
      onClick={goBack}
      size="xsmall"
      style={{
        paddingTop: '5px',
      }}
      {...props}
    >
      <Icon type="angleLeftBigBold" size={16} />
    </Button>
  );
};

const PayButton = ({
  paymentMethod,
  buttonCopy,
  recordComplianceAction,
}: {
  paymentMethod: string;
  buttonCopy: string;
  recordComplianceAction: (action: string) => void;
}) => {
  const { confirm } = useCheckout();
  const { goNext } = useMultiStepContext();
  const [loading, setLoading] = useState(false);

  const handleClick = () => {
    setLoading(true);
    recordComplianceAction('stripe_checkout_payment');

    confirm({ redirect: 'if_required' }).then((result) => {
      if (result.type === 'error') {
        toast(result.error.message, { type: 'error' });
      } else {
        if (paymentMethod !== 'ach_debit') {
          goNext();
        }
      }

      setLoading(false);
    });
  };

  return (
    <Button
      disabled={loading}
      onClick={handleClick}
      color="cta-action"
      width="100%"
      size="large"
    >
      {buttonCopy}
    </Button>
  );
};

export const MobileStripeCheckoutModal = ({
  displayData,
  paymentMethod,
  severity,
}: StripeCheckoutProps) => {
  const { user } = useUser();
  const { createCheckoutSession } = usePayment(user?.agent?.id);
  const { recordComplianceView, recordComplianceAction } = useComplianceEvents({
    severity,
    agentId: user?.agent?.id,
    metadata: displayData.metadata,
    platform: 'mobile_web',
    screen: 'stripe_checkout_view',
  });

  useEffect(() => {
    recordComplianceView();
  }, []);

  const [totalAmount, setTotalAmount] = useState<string | null>(null);

  const { title } = displayData?.modals.stripeCheckout;
  const { dueDate, fees } = displayData?.modals.paymentDue;
  const { content: closedLeads } = displayData?.modals.paymentDue.closedLeads;
  const { content: buttonCopy } = displayData?.modals.stripeCheckout.button;

  const stripePromise = loadStripe(config.stripeAgentRevSharePublicKey, {
    betas: ['custom_checkout_beta_6'],
  });

  const fetchClientSecret = async () => {
    const { client_secret } = await createCheckoutSession(paymentMethod);

    return client_secret;
  };

  return (
    <Box display="block">
      <BackButton />
      <Container>
        <PaymentMethodContainer>
          <Paragraph fontWeight="bold" marginBottom={`${spacings.spacingXs}px`}>
            {title}
          </Paragraph>

          <PriceContainer>
            <Paragraph
              color="cadmiumRed"
              variant={totalAmount ? 'heading-2' : 'text'}
              marginBottom={`${spacings.spacingMicro}px`}
            >
              {!totalAmount ? (
                <span className="--skeleton">
                  Loading skeleton for result title
                </span>
              ) : (
                <span> {totalAmount} </span>
              )}
            </Paragraph>
            <Paragraph variant="text-small" color="cadmiumRed">
              Due {dueDate}
            </Paragraph>

            {fees && parseInt(fees) > 0 && (
              <StatusLabel
                variant="error"
                display="inline-flex"
                mt={`${spacings.spacingNano}px`}
                paddingY={`${spacings.spacingNano}px`}
              >
                $ {fees} late fee added
              </StatusLabel>
            )}
          </PriceContainer>

          <CloseLeadsContainer>
            <Paragraph
              variant="text-small-bold"
              marginBottom={`${spacings.spacingNano}px`}
            >
              Closed leads
            </Paragraph>

            {closedLeads.slice(0, 3).map((closedLead: string, idx: number) => (
              <Paragraph
                key={idx}
                variant="text-small"
                marginBottom={`${spacings.spacingMicro}px`}
                whiteSpace="nowrap"
                overflow="hidden"
                textOverflow="ellipsis"
              >
                {closedLead}
              </Paragraph>
            ))}
          </CloseLeadsContainer>
          {!totalAmount ? <Spinner xl m="auto" mt="50px" /> : null}
          <CheckoutProvider
            stripe={stripePromise}
            options={{
              fetchClientSecret,
              elementsOptions: { loader: 'always' },
            }}
          >
            <StripeCheckoutContent
              buttonCopy={buttonCopy}
              onTotalLoad={setTotalAmount}
              paymentMethod={paymentMethod}
              recordComplianceAction={recordComplianceAction}
            />
          </CheckoutProvider>
        </PaymentMethodContainer>
      </Container>
    </Box>
  );
};

const StripeCheckoutContent = ({
  onTotalLoad,
  paymentMethod,
  buttonCopy,
  recordComplianceAction,
}: {
  onTotalLoad: (amount: string) => void;
  paymentMethod: string;
  buttonCopy: string;
  recordComplianceAction: (action: string) => void;
}) => {
  const { total } = useCheckout();

  useEffect(() => {
    if (total?.total?.amount) {
      onTotalLoad(total.total.amount);
    }
  }, [total?.total?.amount, onTotalLoad]);

  return (
    <Box flexDirection="column">
      <PaymentElement options={{ layout: 'accordion' }} />
      <Box height="8rem" />
      <Footer>
        <PayButton
          paymentMethod={paymentMethod}
          buttonCopy={buttonCopy}
          recordComplianceAction={recordComplianceAction}
        />
      </Footer>
    </Box>
  );
};
