import { useRouter } from 'next/router';

import { useUserEvents } from '@hl-portals/hooks';

type ComplianceMetadata = {
  providerCompliancesIds?: string[];
  providerBillingInfoId?: string;
};

type ComplianceEventParams = {
  severity: string;
  agentId?: string;
  metadata?: ComplianceMetadata;
  platform?: string;
  screen?: string;
};

export const useComplianceEvents = ({
  severity,
  agentId,
  metadata,
  platform,
  screen,
}: ComplianceEventParams) => {
  const { recordUserEvent } = useUserEvents();
  const router = useRouter();

  const leadId = router.query.referralId;
  const medium = router.query.medium;
  const source = router.query.source;

  const recordComplianceView = () => {
    recordUserEvent({
      source: 'agent_dashboard',
      event_action: 'compliance_view',
      includeSource: false,
      result: {
        compliance_type: 'collection',
        screen: screen,
        agent_id: agentId,
        compliance_stage: severity,
        provider_compliances_ids: metadata?.providerCompliancesIds,
        billing_info_id: metadata?.providerBillingInfoId,
        lead_id: leadId,
        source: source,
        medium: medium,
        platform: platform,
      },
    });
  };

  const recordComplianceAction = (action: string) => {
    recordUserEvent({
      source: 'agent_dashboard',
      event_action: 'compliance_action',
      includeSource: false,
      result: {
        compliance_type: 'collection',
        screen: screen,
        user_action: action,
        agent_id: agentId,
        compliance_stage: severity,
        provider_compliances_ids: metadata?.providerCompliancesIds,
        billing_info_id: metadata?.providerBillingInfoId,
        lead_id: leadId,
        source: source,
        medium: medium,
        platform: platform,
      },
    });
  };

  return {
    recordComplianceView,
    recordComplianceAction,
  };
};
