import { colors, fontSizes, spacings } from '@homelight/particle-tokens';

import React, { useEffect } from 'react';

import {
  Box,
  Button,
  Paragraph,
  SimpleLink,
  StatusLabel,
  useMultiStepContext,
} from '@hl-portals/ui';

import { formatCurrency } from '@hl-portals/helpers';

import { useUser } from '../../../../../contexts/Auth';
import { useComplianceEvents } from '../../../../../hooks/useComplianceEvents';
import { usePaymentDueActions } from '../../../../../hooks/usePaymentDueActions';
import { ComplianceModalProps } from '../types';
import { Notice } from './Notice';
import {
  CloseLeadsContainer,
  Container,
  Footer,
  ModalContent,
  NoticeContainer,
  PriceContainer,
} from './styles';

const MAX_CLOSED_LEADS = 3;

export const PaymentDueModal = ({
  displayData,
  severity,
}: ComplianceModalProps) => {
  const { goNext } = useMultiStepContext();
  const { user } = useUser();

  const { recordComplianceView, recordComplianceAction } = useComplianceEvents({
    severity,
    agentId: user?.agent?.id,
    metadata: displayData.metadata,
    platform: 'desktop_web',
    screen: 'payment_due_modal',
  });

  const { isLoading, onSupportTaskClick } = usePaymentDueActions(
    user?.agent?.id
  );

  useEffect(() => {
    recordComplianceView();
  }, []);

  const handleOnSupportTaskClick = () => {
    recordComplianceAction('closing_specialist');
    onSupportTaskClick();
  };

  const onPaymentDueClick = () => {
    recordComplianceAction('pay_now');
    goNext();
  };

  const {
    title,
    subTitle,
    total,
    dueDate,
    fees,
    firstNoticeTitle,
    firstNoticeSubTitle,
    finalNoticeTitle,
    finalNoticeSubTitle,
  } = displayData?.modals.paymentDue;

  const { content: closedLeads } = displayData?.modals.paymentDue.closedLeads;
  const { content: buttonLabel } = displayData?.modals.paymentDue.button;
  const { content: linkLabel } = displayData?.modals.paymentDue.link;

  const faded = severity !== 'restriction';

  return (
    <Container>
      <ModalContent>
        <Paragraph fontWeight="bold" marginBottom={`${spacings.spacingNano}px`}>
          {title}
        </Paragraph>

        <Paragraph
          fontWeight="regular"
          marginBottom={`${spacings.spacingSm}px`}
        >
          {subTitle}
        </Paragraph>

        <Box flexDirection="row" gap={`${spacings.spacingXxxs}px`}>
          <Box flexDirection="column" flex={1}>
            <PriceContainer>
              <Paragraph
                color="cadmiumRed"
                variant="heading-2"
                marginBottom={`${spacings.spacingMicro}px`}
              >
                {formatCurrency({ value: parseInt(total) / 100 })}
              </Paragraph>

              <Paragraph variant="text-small" color="cadmiumRed">
                Due {dueDate}
              </Paragraph>

              {fees && parseInt(fees) > 0 && (
                <StatusLabel
                  variant="error"
                  display="inline-flex"
                  mt={`${spacings.spacingNano}px`}
                  paddingY={`${spacings.spacingNano}px`}
                >
                  $ {fees} late fee added
                </StatusLabel>
              )}
            </PriceContainer>

            <CloseLeadsContainer>
              <Paragraph
                variant="text-small-bold"
                marginBottom={`${spacings.spacingNano}px`}
              >
                Closed leads
              </Paragraph>

              {closedLeads
                .slice(0, MAX_CLOSED_LEADS)
                .map((closedLead: string, idx: number) => (
                  <Paragraph
                    key={idx}
                    variant="text-small"
                    marginBottom={`${spacings.spacingMicro}px`}
                    whiteSpace="nowrap"
                    overflow="hidden"
                    textOverflow="ellipsis"
                  >
                    {closedLead}
                  </Paragraph>
                ))}
            </CloseLeadsContainer>
          </Box>

          <Box flexDirection="column" flex={1}>
            <NoticeContainer>
              <Notice title={firstNoticeTitle} subtitle={firstNoticeSubTitle} />

              <Notice
                title={finalNoticeTitle}
                subtitle={finalNoticeSubTitle}
                faded={faded}
                finalNotice
              />
            </NoticeContainer>
          </Box>
        </Box>

        <Footer>
          <SimpleLink
            mt={`${spacings.spacingXxxs}px`}
            fontSize={`${fontSizes.fontSizeSm}px`}
            color={colors.electricBlue}
            underlined
            onClick={handleOnSupportTaskClick}
          >
            {linkLabel}
          </SimpleLink>

          <Button
            isLoading={isLoading}
            color="cta-action"
            size="large"
            onClick={onPaymentDueClick}
          >
            {buttonLabel}
          </Button>
        </Footer>
      </ModalContent>
    </Container>
  );
};
