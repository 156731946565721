import styled from 'styled-components';

import { Button } from '@hl-portals/ui';

import { media } from '@hl-portals/helpers';

export const BannerCta = styled(Button)`
  font-size: 1rem;
  font-weight: 600;

  ${media.desktop`
    margin-left: 1.5rem;
  `}
`;
