import { borderWidths, colors, radii } from '@homelight/particle-tokens';
import styled from 'styled-components';

import { Box } from '@hl-portals/ui';

import { media } from '@hl-portals/helpers';

export const Container = styled.div`
  width: 100%;
  margin: 3rem auto 0;
  overflow-y: scroll;

  ${media.tablet`
    max-width: 476px;
    margin-top: 3rem;
  `}
`;

export const SectionContainer = styled.div`
  flex-direction: column;
`;

export const SummaryContainer = styled(SectionContainer)`
  width: '100%';
`;

export const PaymentMethodContainer = styled(SectionContainer)`
  width: '100%';
  flex-grow: 1;
`;

export const Footer = styled(Box)`
  padding: 1rem;
  background: white;

  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  flex-direction: column;
  z-index: 1;
  border-top: solid 1px ${colors.coolGray4};
  gap: 1rem;

  & > * {
    height: 3rem;
    line-height: 3rem;
  }
`;

const BaseContainer = styled.div`
  width: auto;
  height: auto;
  flex-grow: 1;
  padding: 1rem 1.5rem;
`;

export const PriceContainer = styled(BaseContainer)`
  gap: 0.5rem;
  border-radius: ${radii.borderRadius12}px;
  border: 2px solid ${colors.cadmiumRed};
  margin-bottom: 0.75rem;
`;

export const CloseLeadsContainer = styled(BaseContainer)`
  gap: 0.25rem;
  border-radius: ${radii.borderRadius12}px;
  border: ${borderWidths.borderWidth1}px solid ${colors.coolGray4};
  margin-bottom: 0.75rem;
`;

export const PaymentMethodItemContainer = styled(BaseContainer)`
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: space-between;
  padding: 0;
`;
