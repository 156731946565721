import { borderWidths, colors, radii } from '@homelight/particle-tokens';
import styled from 'styled-components';

import { Box } from '@hl-portals/ui';

import { media } from '@hl-portals/helpers';

export const Container = styled.div`
  display: flex;
`;

export const SectionContainer = styled.div`
  max-width: 365px;
  flex-direction: column;
  padding: 48px 20px 20px;
`;

export const SummaryContainer = styled(SectionContainer)`
  width: 349px;
  background: #f8f8fb;
  position: relative; /* Needed for absolute positioning */

  &::after {
    content: '';
    position: absolute;
    top: -50px; /* Extends 50px upwards */
    left: -50px; /* Extends 50px to the left */
    width: calc(100% + 50px); /* Ensures full width */
    height: calc(100% + 100px); /* Extends 50px on top & bottom */
    background: #f8f8fb;
    z-index: -1; /* Places it behind content */
  }
`;
export const PaymentMethodContainer = styled(SectionContainer)`
  width: 349px;
  flex-grow: 1;
`;

export const Footer = styled(Box)`
  padding-top: 11.1rem;
  justify-content: center;
  gap: 0.75rem;
  background: white;
  width: 100%;
  position: relative;

  ${media.untilTablet`
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    flex-direction: column;
    padding: 0 1.5rem 1.75rem;

    & > * {
      height: 3rem;
      line-height: 3rem;
    }
  `};
`;

const BaseContainer = styled.div`
  width: auto;
  max-width: 365px;
  padding: 1rem 1.5rem;
`;

export const PriceContainer = styled(BaseContainer)`
  gap: 0.5rem;
  border-radius: ${radii.borderRadius12}px;
  border: ${borderWidths.borderWidth1}px solid ${colors.cadmiumRed};
  margin-bottom: 0.75rem;
  background: white;
`;

export const CloseLeadsContainer = styled(BaseContainer)`
  gap: 0.25rem;
  border-radius: ${radii.borderRadius12}px;
  border: ${borderWidths.borderWidth1}px solid ${colors.coolGray4};
  background: white;
`;

export const PaymentMethodItemContainer = styled(BaseContainer)`
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: space-between;
  padding: 0;
`;
